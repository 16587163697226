import React, { useEffect, useState } from "react";
// import LandingNavbar from "../components/sidebar/LandingNavbar";
import { Navigate, useLocation } from "react-router-dom";
import HeaderNew from "../component/HeaderNew";
import CryptoJS from "crypto-js";

const PublicRoute = ({ ...rest }) => {
  const token = localStorage.getItem("token");
  const [decryptedRole, setDecryptedRole] = useState(null);
  // console.log(userRole);

  useEffect(() => {
    const encryptedRole = localStorage.getItem("user");
    if (encryptedRole) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedRole,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedRole = bytes.toString(CryptoJS.enc.Utf8);
      setDecryptedRole(decryptedRole);
    }
  }, []);
  if (!token) {
    return <>
    {rest.element}</>;
  } else if (token && decryptedRole === "Admin") {
    return <Navigate to="/" />;
  } else if (token && decryptedRole === "Operations") {
    return <Navigate to="/" />;
  }
};
export default PublicRoute;


