import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import { SmallButton } from "../coustombutton/SmallButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import CardComponent from "../../common/card/CardComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { utcToIst } from "../../common/utctimechange";
import { useUser } from "../../contexts/UserDataStore";

export default function MarqinfluncersView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  const { user } = useUser();
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "status", label: " Current Status", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
    { id: "updatedby", label: "Updated By", minWidth: 200 },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };

  const realData = rowData?.adminNoteHistory.map((item,index) => ({
    sno: index+1,

    adminnote: item.adminNote && item.adminNote,
    status: item.status && item.status?.status,
    updatedat:
    item.updatedAt && utcToIst(item.updatedAt),    updatedby: item.updatedBy?.name,
  }));
  const list = (
    <Box
      sx={{ width: showDetails ? 500 : 1000 }}
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer" style={{userSelect: user?.role === "Operations" ? "none" : "",}}>
          <div className="brands_drawer_name_section">
            <h1>{showDetails ? "Details" : "Notes"}</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            {showDetails && (
              <Tooltip placement="top" title="Brand History">
                <HistoryIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(true);
                    setShowDetails(false);
                  }}
                />
              </Tooltip>
            )}
            {showHistory && (
              <Tooltip placement="top" title="Brand Details">
                <VisibilityIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(false);
                    setShowDetails(true);
                  }}
                />
              </Tooltip>
            )}
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              {showHistory && (
                <>
                  <ShortingTable
                    columns={columns}
                    // loading={activeCampaignDetailsLoading}
                    data={realData}
                  />

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
              {showDetails && (
                <>
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Marqfluencer Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.influencerName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Mobile Number</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.mobileNumber}
                    </CustomProductSubheadingh>
                  </Grid>
                
                  {rowData?.email && (
                    <> <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                      <div>
                        <CustomInputLabel>Email Address</CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {rowData?.email}
                      </CustomProductSubheadingh>
                    </Grid>
                    </>
                  )}

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Category</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.influencerCategory
                        .map((categoryObj) => categoryObj.influencerCategory)
                        .join(", ")}
                    </CustomProductSubheadingh>
                  </Grid>

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Collaboration Type</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.collaborationType?.colabType}
                    </CustomProductSubheadingh>
                  </Grid>

                 
                  {rowData?.commercials && (
                    <> <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                      <div>
                        <CustomInputLabel>Commercials</CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {rowData?.commercials}
                      </CustomProductSubheadingh>
                    </Grid>
                    </>
                  )}
                
                  {rowData?.instagramUrl && (
                    <> <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                      <div>
                        <CustomInputLabel>Instagram URL</CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {rowData?.instagramUrl}
                      </CustomProductSubheadingh>
                    </Grid>
                    </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
{rowData?.youtubeUrl && (
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Youtube URL</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.youtubeUrl}
                    </CustomProductSubheadingh>
                  </Grid>
                  )}
               
                  {rowData?.country && rowData?.state && rowData?.city &&(
                    
                    <> 
                    <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Country Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.country?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>State Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.state?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>City Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.city?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  </>
                  )}
                 
                  {rowData?.upiId && (
                    <> <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>UPI Id</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.upiId}
                    </CustomProductSubheadingh>
                  </Grid>
                  </>
                  )}
               
                  {rowData?.holderName && (
                    <> <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>UPI Holder Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.holderName}
                    </CustomProductSubheadingh>
                  </Grid>
                  </>
                  )}
                
                  {rowData?.influencerDescription && (
                    <> <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>
                        Marqfluencer Description
                      </CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.influencerDescription}
                    </CustomProductSubheadingh>
                  </Grid>
                  </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": { overflow: "auto", background: "#f4faff" },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
