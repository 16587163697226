import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useCItyByCountries,
  useCollbType,
  useCountries,
  useGetInfluncers,
  useInfluncersCategory,
  useStates,
} from "../../component/customHooks/customHook";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import RemovePopup from "../../component/removemasterdata/removePopup";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import MarqinfluncersView from "../../component/viewdetails/MarqinfluncersView";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import FilterPopupComponent from "../../common/filter/filterPopup";
import BrandNoteHistory from "../../component/adminhistory/NoteHistory";
import HistoryIcon from "@mui/icons-material/History";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import { useDispatch, useSelector } from "react-redux";
import { setMarqfluencerFilter } from "../../store/FilterSlice";

const sortArray = [
  {
    sortOrder: "asc",
    sortField: "influencerName",
    sortName: "Marqfluencer Name (Asc)",
  },
  // {
  //   sortOrder: "asc",
  //   sortField: "createdAt",
  //   sortName: "Created Date",
  // },
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
];

const MarqfluencerComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.marqfluencerFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sortField, setSortField] = useState("");
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [editState, setEditState] = useState();
  const influencercategies = useInfluncersCategory();
  const countries = useCountries();
  // const cityFilters = useCItyByCountries();
  // console.log(cityFilters);
  const states = useStates("India");
  const collabType = useCollbType();
  const influencerNameNew = useGetInfluncers();
  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "influencername", label: "Name", minWidth: 200 },
    { id: "mobile", label: "Mobile", minWidth: 200 },
    // { id: "email", label: "Email", minWidth: 200 },
    { id: "category", label: "Category", minWidth: 200 },
    { id: "colloboration", label: "Colloboration", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "lastLoggedIn", label: "Last LoggedIn", minWidth: 100 },

    // { id: "instaurl", label: "Instagram URL", minWidth: 200 },
  ];

  const { allstatus, loading, totalPages, totalCount } = useGetInfluncers(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh
  );

  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,

    influencername: item.influencerName,
    mobile: item.mobileNumber,
    // email: item.email,
    category: item.influencerCategory
      .map((categoryObj) => categoryObj.influencerCategory)
      .join(", "),
    colloboration: item.collaborationType
      ? item.collaborationType.colabType
      : "",
    // instaurl: item.instagramUrl,
    country: item.country && item.country.name,
    state: item.state && item.state.name,
    city: item.city ? item.city.name : "",
    status: item.status && item.status?.status,
    lastLoggedIn: item.lastLoggedIn && item.lastLoggedIn,

  }));
  let filters = [
    {
      label: "Category",
      urlParam: "influencercategory",
      values: influencercategies?.allstatus.map((item) => ({
        label: item.influencerCategory,
        value: item._id,
      })),
    },
    {
      label: "Country",
      urlParam: "country",
      values: countries.map((item) => ({
        label: item.name,
        value: item._id,
      })),
    },
    {
      label: "State",
      urlParam: "state",
      values: states.map((item) => ({
        label: item.name,
        value: item._id,
      })),
    },

    {
      label: "Collaboration",
      urlParam: "collaborationtype",
      values: collabType?.allstatus.map((item) => ({
        label: item.colabType,
        value: item._id,
      })),
    },
    {
      label: "Marqfluencer",
      urlParam: "influencer",
      values: influencerNameNew?.allstatus.map((item) => ({
        label: `${item.influencerName} (${item.mobileNumber})`,
        value: item._id,
      })),
    },
    // {
    //     label: "City",
    //     urlParam: "city",
    //     values: cityFilters?.map((item) => ({
    //       label: item.name,
    //       value: item._id,
    //     })),
    //   }
  ];
  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setHistoryDrawerOpen(true);
      },
    },
    {
      icon: SignalCellularAltIcon,
      onClick: (row) => navigate(`/marqfluencer/stats/${row.id}`),
    },
    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/marqfluencer/edit", { state: { selectedData } });
      
      },
    },
    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
    {
      icon: NoteAddIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row);
        handleOpenModal();
      },
    },
  ];
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };
  const setParam = (value) => {
    if (filterParam !== value) {
    dispatch(setMarqfluencerFilter(value));
    setCurrentPage(1);
    setTriggerRefresh(prev => !prev);}
  };


  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_INFLUNCERS"
        onSuccess={refreshData}

      />
      <MarqinfluncersView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        statusGroup="Marqfluencer"
        // onFormSubmit={/* Pass any necessary submit function here */}
        editState={editState}
        endpoint="ADMIN_INFLUENCERS_NOTE"
        onSuccess={refreshData}

      />
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Marqfluencer", href: "/marqfluencer" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Marqfluencer</p>
              <span>{totalCount}&nbsp;Total Marqfluencer</span>
            </div>
            <div>
              <CustomButton
                onClick={() => navigate("/marqfluencer/add")}
                label="Add Marqfluencer"
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="marqfluencerFilter" 

            />
          </div>

          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
            userNone={true}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default MarqfluencerComponent;
