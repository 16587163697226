import React, { useState } from 'react';
 
const CustomTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
 
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
 
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
 
  return (
<div style={{ position: 'relative' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showTooltip && (
<div style={{ position: 'absolute', top: '100%',width:"200px", left: '50%', transform: 'translateX(-50%)', backgroundColor: '#ccc', color: '#163e6e', padding: '5px', borderRadius: '5px' , 
                    fontFamily: '"Outfit", sans-serif',
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: "center",}}>
          {text}
</div>
      )}
</div>
  );
};
 
export default CustomTooltip;