import React, { useEffect, useState } from "react";
import {
  CustomRequiredLabel,
  CustomInputLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import { Card, CardContent, CircularProgress, FormControl, Grid } from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  useGetBrand,
  useGetCampaign,
  useGetPayment,
  useGetPayments,
} from "../customHooks/customHook";
import { postJsonData, putJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import SubmitButton from "../coustombutton/SubmitButton";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import {Admin} from "../../common/Constant"
import { useUser } from "../../contexts/UserDataStore";

const EditPayment = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const paymentData = state?.selectedData || {};
  const [formData, setFormData] = useState({
    paymentamount: {
      value: paymentData?.paymentAmount || "",
      isValid: true,
    },
    paymentdate: {
      value: paymentData?.paymentDate
        ? new Date(paymentData?.paymentDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [allBrand, setallBrand] = useState("");
  const [paymentModetype, setPaymentModetype] = useState("");
  const [campaignName, setcampaignName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const navigate = useNavigate();
  const brandDetails = useGetBrand();
  const paymentMode = useGetPayment();
  const campaign = useGetCampaign();

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  // const preSelectedmode = paymentMode?.allstatus.find(item => item.paymentMode === paymentData.paymentMode.paymentMode);

  // console.log(preSelectedmode?.paymentMode,"loki")
  // console.log(preSelectedmode?._id,"loki")
  // console.log(brandDetails?.allstatus,"loki")

  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand({
        _id: selectedOption.value,
        platform: selectedOption.label,
      });
    } else {
      setallBrand("");
    }
  };

  const handlePaymentMode = (selectedOption) => {
    if (selectedOption) {
      setPaymentModetype({
        _id: selectedOption.value,
        paymentMode: selectedOption.label,
      });
    } else {
      setPaymentModetype("");
    }
  };
  const handleCampaign = (selectedOption) => {
    if (selectedOption) {
      setcampaignName({
        _id: selectedOption.value,
        campaignName: selectedOption.label,
      });
    } else {
      setcampaignName("");
    }
  };





  const handlePaymentEdit = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid =
      formData.paymentamount.isValid ||
      (paymentData?.paymentAmount &&
        // formData.taxinvoicenumber.isValid &&
        // formData.Pioicenumber.isValid &&
        // paymentModetype &&
        // paymentModetype &&
        // campaignName &&
        formData.paymentamount.value.trim() !== "");
    if (isFormValid) {
      const editData = {
        brand: allBrand?.brand?._id ||  allBrand?._id,
        paymentAmount:
          formData.paymentamount.value || paymentData?.paymentAmount,
        paymentDate:
          formData.paymentdate.value ||
          new Date(paymentData?.paymentDate).toISOString().split("T")[0],
        paymentMode: paymentModetype?.paymentMode?._id || paymentModetype?._id, 
        campaign: campaignName?.campaign?._id ? campaignName.campaign._id : campaignName?._id,
      };

      const endpoint = `${VisitorEndpoint.EDIT_PAYMENT}/${paymentData?._id}`;
      putJsonData(
        endpoint,
        editData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/payment");
            }, 2000);
          }
          setIsDisabledNew(false);

        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);

        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);

    }
  };

  useEffect(() => {
    setLoading(true);
    setallBrand(paymentData);
    setcampaignName(paymentData);
    setPaymentModetype(paymentData)
    setLoading(false);
  }, [paymentData]);
  return (
    <div className="container_fluid_new">
      <div className="container_new">
      {loading ? (
          <CircularProgress />
        ) : (
          <>
        <div>
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Payment", href: "/payment" },
              { name: "Edit Payment", href: "/payment/edit" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Edit Payment</h1>
        <form onSubmit={handlePaymentEdit}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the brand for which payment is received.
                  </CustomSubheadingh>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={brandDetails?.allstatus.map((item) => ({
                        label: item.brandName,
                        value: item._id,
                      }))}
                      value={allBrand}
                      // defaultValue={preSelectedBrand ? { label: preSelectedBrand.brandName, value: preSelectedBrand._id } : null}
                      onChange={handleBrand}
                      name="flavor"
                      isDisabled={ user?.role !== Admin &&
                        paymentData && paymentData.brand
                          ? !!paymentData.brand.brandName
                          : false
                      }
                      defaultValue={
                            allBrand && allBrand?.brand
                              ? {
                                  label: allBrand?.brand?.brandName,
                                  value: allBrand?.brand?._id,
                                }
                              : ""
                          }
                    />
                    {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                    Campaign Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the campaign for which payment is received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={campaign?.allstatus.map((item) => ({
                        label: item.campaignName,
                        value: item._id,
                      }))}
                      value={campaignName}
                      // defaultValue={(preSelectedCampaign ? { label: preSelectedCampaign.campaignName, value: preSelectedCampaign._id } : null)}
                      onChange={handleCampaign}
                      name="flavor"
                      isDisabled={ user?.role !== Admin &&
                        paymentData && paymentData.campaign
                          ? !!paymentData.campaign.campaignName
                          : false
                      }
                      defaultValue={
                        campaignName && campaignName?.campaign
                              ? {
                                  label: campaignName?.campaign?.campaignName,
                                  value: campaignName?.campaign?._id,
                                }
                              : ""
                          }
                    />
                 
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Payment Date
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the date on which payment is received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="paymentdate"
                      type="date"
                      placeholder="Enter Payment Date"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.paymentdate.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "paymentdate", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Payment Mode
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the mode on which payment is received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={paymentMode?.allstatus.map((item) => ({
                        label: item.paymentMode,
                        value: item._id,
                      }))}
                      value={paymentModetype}
                      // value={paymentModetype || (preSelectedmode ? { label: preSelectedmode.paymentMode, value: preSelectedmode._id } : null)}
                      onChange={handlePaymentMode}
                      name="flavor"
                      defaultValue={
                        paymentModetype && paymentModetype?.paymentMode
                              ? {
                                  label: paymentModetype?.paymentMode?.paymentMode,
                                  value: paymentModetype?.paymentMode?._id,
                                }
                              : ""
                          }

                    />
                    {formSubmitted && !paymentModetype && (
                      <p className="input_text_error">
                        *Please select payment Mode.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Payment Amount
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the amount which have been received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="paymentamount"
                      type="number" 
    onWheel={(e) => e.target.blur()}
                      placeholder="Enter Payment Amount"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.paymentamount.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "paymentamount", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <SubmitButton
                  type={isDisabledNew ? "button":"submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />{" "}          </CardContent>
          </Card>
        </form>
        </>)}
      </div>
    </div>
  );
};
export default EditPayment;
