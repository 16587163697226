import React, { useEffect, useState, useCallback } from "react";
import { PATTERNS } from "../validetion";
import { Button, Card, CardContent, FormControl, Grid } from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useCamapignProductByBrandId,
  useGetBrand,
  useGetCampaignType,
  useGetPlatform,
  useGetProduct,
  useGetStatus,
} from "../customHooks/customHook";
import { postFormData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import { SmallButton } from "../coustombutton/SmallButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
const AddCampaign = () => {
  const [formData, setFormData] = useState({
    camapignname: {
      value: "",
      isValid: true,
    },
    campaignbrief: {
      value: "",
      isValid: true,
    },
    noofcontentpieces: {
      value: "",
      isValid: true,
    },
    servicecostpercontent: {
      value: "",
      isValid: true,
    },
    totalservicecost: {
      value: "",
      isValid: true,
    },
    // totalcost: {
    //   value: "",
    //   isValid: true,
    // },
    reimbursementcost: {
      value: "",
      isValid: true,
    },
    totalcampaigncost: {
      value: "",
      isValid: true,
    },
    proformainvoiceno: {
      value: "",
      isValid: true,
    },
    taxInvoiceno: {
      value: "",
      isValid: true,
    },
    startdate: {
      value: "",
      isValid: true,
    },
    enddate: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [allBrand, setallBrand] = useState("");
  const [campaignStatus, setCamapignStatus] = useState("");
  const [campaignTypeName, setCampaignTypeName] = useState("");
  const [campaignPlatform, setCampaignPlatform] = useState("");
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [editorQuestionHtml, setEditorQuestionHtml] = useState();
  const [isTotalCostManuallyEdited, setIsTotalCostManuallyEdited] =
    useState(false);
  const brandDetails = useGetBrand();

  // const productDetails = useGetProduct();
  const productDetails = useCamapignProductByBrandId(allBrand);

  const campaignType = useGetCampaignType();
  const platform = useGetPlatform();
  const [products, setProducts] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [taxImageFile, setImageTaxFile] = useState(null);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const addProduct = () => {
    setProducts([...products, { productName: "", quantity: 0 }]);
  };

  const allProductsSelected = productDetails?.length === products.length;
  let quillRef;

  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      const values = selectedOption.map((option) => option.value);
      setCampaignPlatform(values);
    } else {
      setCampaignPlatform("");
    }
  };

  const renderContent = useCallback((content = "") => {
    const sanitizedBlog = DOMPurify.sanitize(content);
    const renderHTML = () => (
      <div dangerouslySetInnerHTML={{ __html: sanitizedBlog }}></div>
    );
    return renderHTML();
  }, []);
  const handleCampaignType = (selectedOption) => {
    if (selectedOption) {
      setCampaignTypeName(selectedOption.value);
    } else {
      setCampaignTypeName("");
    }
  };
  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand(selectedOption.value);
    } else {
      setallBrand("");
    }
  };

  const handleProductName = (selectedOption, index) => {
    const newProducts = [...products];
    newProducts[index].productName = selectedOption ? selectedOption.value : "";
    setProducts(newProducts);
    if (selectedOption) {
      setSelectedProductIds([...selectedProductIds, selectedOption.value]);
    }
  };

  const handleProductQuantity = (event, index) => {
    const newProducts = [...products];
    newProducts[index].quantity = event.target.value;
    setProducts(newProducts);
  };

  const removeProduct = (index) => {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    setProducts(newProducts);
  };

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const navigate = useNavigate();

  const handlePerformaFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };
  const handleTaxFileChange = (e) => {
    setImageTaxFile(e.target.files[0]);
  };

  const totalServiceCostInput =
    formData.noofcontentpieces.value * formData.servicecostpercontent.value;

  const totalCampaignCost =
    Number(totalCost) +
    totalServiceCostInput +
    Number(formData.reimbursementcost.value);

  useEffect(() => {
    if (!isTotalCostManuallyEdited) {
      const newGstValue = totalServiceCostInput * 0.18;
      setTotalCost(newGstValue);
    }
  }, [totalServiceCostInput, isTotalCostManuallyEdited]);

  const handleTotalCostChange = (e) => {
    // Allow editing the totalCost input
    setTotalCost(e.target.value);
    setIsTotalCostManuallyEdited(true);
  };
  const handleCampaign = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid = formData.camapignname.isValid;

    // formData.companyname.isValid &&
    // formData.companyaddress.isValid &&
    // formData.companygst.isValid &&
    // formData.companypan.isValid &&
    // formData.brandwebsite.isValid &&
    // formData.brandname.value.trim() !== "" &&
    // formData.brandpocname.value.trim() !== "" &&
    // formData.phone.value.trim() !== "" &&
    // formData.brandwebsite.value.trim() !== "";
    if (isFormValid) {
      const data = {
        campaignName: formData.camapignname.value,
        campaignStartDate: formData.startdate.value,
        campaignEndDate: formData.enddate.value,
        noOfContent: formData.noofcontentpieces.value,
        gstCost: totalCost,
        serviceCostPerContent: formData.servicecostpercontent.value,
        // totalServiceCost: formData.totalservicecost.value,
        totalServiceCost: totalServiceCostInput,
        reimbursementCost: formData.reimbursementcost.value,
        totalCampaignCost: Math.round(totalCampaignCost),
        taxInvoiceNumber: formData.taxInvoiceno.value,
        proformaInvoiceNumber: formData.proformainvoiceno.value,
        campaignBrief: editorQuestionHtml?editorQuestionHtml:"" ,
        campaignType: campaignTypeName,
        // campaignPlatform: campaignPlatform,
        brand: allBrand,
      };
      if (imageFile) {
        data.proformaInvoiceUpload = imageFile;
      }
      if (imageFile) {
        data.taxInvoiceUpload = taxImageFile;
      }
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      products.forEach((product, index) => {
        newData.append(`product[${index}][productId]`, product.productName);
        newData.append(`product[${index}][productQuantity]`, product.quantity);
      });
      campaignPlatform.forEach((value, index) => {
        newData.append(`campaignPlatform[${index}]`, value);
      });
      postFormData(
        VisitorEndpoint.ADD_CAMPAIGN,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/campaign");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
  const handleQuestionEditor = (value) => {
    setEditorQuestionHtml(value);
    // handleFieldChange("QuestionText", value);
  };
  const module = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["formula"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "list",
    "bullet",
    "blockquote",
    "code-block",
    "align",
    "formula",
    "image",
    "video",
    "color",
  ];
  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          {" "}
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Campaign", href: "/campaign" },
              { name: "Add Campaign", href: "/campaign/add" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Add Campaign</h1>
        <form onSubmit={handleCampaign}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Campaign Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the name of the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="camapignname"
                      type="text"
                      placeholder="Enter Campaign Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "camapignname", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the brand for the campaign.
                  </CustomSubheadingh>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={brandDetails?.allstatus.map((item) => ({
                        label: item.brandName,
                        value: item._id,
                      }))}
                      value={allBrand}
                      onChange={handleBrand}
                      name="flavor"
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Product</CustomRequiredLabel>
                  <CustomSubheadingh>Add product..</CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <SmallButton
                    onClick={addProduct}
                    type="button"
                    label="Add Products"
                    disabled={allProductsSelected}
                  />
                </Grid>
              </Grid>
              {products.map((product, index) => (
                <Grid key={index}>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Product Name</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the product for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          // options={productDetails
                          //   ?.filter(
                          //     (item) => !selectedProductIds.includes(item._id)
                          //   )
                          //   .map((item) => ({
                          //     label: item.productName,
                          //     value: item._id,
                          //   }))}
                          options={productDetails?.map((item) => ({
                            label: item.productName,
                            value: item._id,
                          }))}
                          value={product.productName}
                          onChange={(selectedOption) =>
                            handleProductName(selectedOption, index)
                          }
                          name="flavor"
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="productquentity"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter product Quantity"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => handleProductQuantity(e, index)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <div>
                        {" "}
                        <DeleteIcon
                          sx={{
                            color: "#163e6e",
                            width: "35px",
                            height: "35px",
                          }}
                          onClick={() => removeProduct(index)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Campaign Platform
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the platform for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={platform?.allstatus.map((item) => ({
                        label: item.platform,
                        value: item._id,
                      }))}
                      value={campaignPlatform}
                      onChange={handlePlatform}
                      name="flavor"
                      isMulti={true}
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Campaign Type
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the type of the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={campaignType?.allstatus.map((item) => ({
                        label: item.campaignType,
                        value: item._id,
                      }))}
                      value={campaignTypeName}
                      onChange={handleCampaignType}
                      name="flavor"
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Campaign Brief
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the brief desciption about the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <div className="form-group mb-4">
                      <ReactQuill
                        ref={(el) => {
                          quillRef = el;
                        }}
                        theme="snow"
                        value={editorQuestionHtml}
                        onChange={handleQuestionEditor}
                        modules={module}
                        formats={formats}
                      />
                      {/* {formik.touched.QuestionText && formik.errors.QuestionText && (
            <div className="error">{formik.errors.QuestionText}</div>
          )} */}
                    </div>
                    {/* <DescriptionInput
                      required
                      name="campaignbrief"
                      type="text"
                      placeholder="Enter Campaign Brief"
                      id="outlined-required"
                      label="First Name"
                      helper
                      multiline
                      rows={4}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "campaignbrief", null);
                      }}
                    /> */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Start Date</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the date on which campaign needs to be started.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="startdate"
                      type="date"
                      placeholder="Enter Company Legal Address"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "startdate", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>End Date</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the date on which campaign is completed.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="enddate"
                      type="date"
                      placeholder="Enter Company Legal Address"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "enddate", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    No of Content Pieces
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the number of content pieces required in the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="noofcontentpieces"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter No of Content Pieces"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "noofcontentpieces", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Service Cost Per Content
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the cost per content for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="servicecostpercontent"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Service Cost Per Content"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                        ];
                        if (
                          !(
                            (e.key >= "0" && e.key <= "9") ||
                            e.key === "." ||
                            allowedKeys.includes(e.key)
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setValues(e, "servicecostpercontent", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Total Service Cost
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the total service cost for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="totalservicecost"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Total Service Cost"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={totalServiceCostInput}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "totalservicecost", null);
                      }}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Total GST</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the total GST cost for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="totalcost"
                      type="text"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Total GST"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={totalCost}
                      size="small"
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                        ];
                        if (
                          !(
                            (e.key >= "0" && e.key <= "9") ||
                            e.key === "." ||
                            allowedKeys.includes(e.key)
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={handleTotalCostChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Total Reimbursement Cost
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the total reimbursement cost for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="reimbursementcost"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Total Reimbursement Cost"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "reimbursementcost", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Total Campaign Cost
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the total campaign cost for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="totalcampaigncost"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Total Campaign Cost"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      value={Math.round(totalCampaignCost)}
                      disabled={true}
                      // onChange={(e) => {
                      //   setValues(e, "totalcampaigncost", null);
                      // }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                    Proforma Invoice No.
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the proforma invoice number for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="proformainvoiceno"
                      type="text"
                      placeholder="Enter Proforma Invoice No."
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "proformainvoiceno", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                    Proforma Invoice File
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Upload the file of the Proforma Invoice.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="file"
                      type="file"
                      placeholder="Enter Product Image"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={handlePerformaFileChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Tax Invoice No.</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the tax invoice number for the campaign.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="taxInvoiceno"
                      type="text"
                      placeholder="Enter Tax Invoice No."
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "taxInvoiceno", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Tax Invoice File</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Upload the file of tax invoice.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="file"
                      type="file"
                      placeholder="Enter Product Image"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={handleTaxFileChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <SubmitButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
              />{" "}
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default AddCampaign;
