import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Divider, CircularProgress } from "@mui/material";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";

export default function WebBrandsView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };

  const list = (
    <Box
      sx={{ width: showDetails ? 500 : 1000 }}
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>{showDetails ? "Details" : "Notes"}</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              {showDetails && (
                <>
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Full Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.fullName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Mobile Number</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.mobileNumber}
                    </CustomProductSubheadingh>
                  </Grid>
                  {rowData?.email && (
                    <>
                      {" "}
                      <Divider
                        sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                      />
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                        <div>
                          <CustomInputLabel>Email Address</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.email}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Brand Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.brandName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Brand Website</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.brandWebsite}
                    </CustomProductSubheadingh>
                  </Grid>
                  {rowData?.brandCategory &&
                    rowData.brandCategory.length > 0 && (
                      <>
                        <Divider
                          sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                        />

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{ paddingBottom: "10px" }}
                        >
                          <div>
                            <CustomInputLabel>Category</CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {rowData.brandCategory
                              .map((categoryObj) => categoryObj.brandCategory)
                              .join(", ")}
                          </CustomProductSubheadingh>
                        </Grid>
                      </>
                    )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign Requirement</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.campaignRequirement?.title}
                    </CustomProductSubheadingh>
                  </Grid>{" "}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Collaboration Budget</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.collaborationBudget}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign StartDate</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.estimatedCampaignStartDate}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Country Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.country?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>State Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.state?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>City Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.city?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  {rowData?.otherCity && (
                    <>
                      {" "}
                      <Divider
                        sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                      />
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                        <div>
                          <CustomInputLabel>Other City</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.otherCity}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  {rowData?.otherCategory.length > 0 && (
                    <>
                      {" "}
                      <Divider
                        sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                      />
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                        <div>
                          <CustomInputLabel>Other Category</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.otherCategory}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": { overflow: "auto", background: "#f4faff" },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
