import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { getAxios } from "../../Services/AxoisInterceptorr";

export function useGetCampaignType(currentPage, perPageLimit, triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCampaignType = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.CAMPAIGN_TYPE +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetCampaignType();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useGetCollab(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCollab = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_COLLAb +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetCollab();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetPayment(currentPage, perPageLimit, triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPayment = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.PAYMENT_LIST +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPayment();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useGetPlatform(currentPage, perPageLimit, triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.PLATFORM_LIST +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useGetBrand(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_BRAND +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetProduct(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetProduct = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_PRODUCT +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetProduct();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetInfluncers(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetInfluncer = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_INFLUNCERS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetInfluncer();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useGetCampaign(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCamapign = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_CAMPAIGNS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetCamapign();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetOrders(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetOrders = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_ORDERS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetOrders();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetPayments(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPayments = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_PAYMENT +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPayments();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetReimbursement(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetReimbursement = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_REIMBURSEMENT +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetReimbursement();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetStatus(
  currentPage,
  perPageLimit,
  statusGroup,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetStatus = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.STATUS_LIST +
      `?currentpage=${currentPage}&limit=${perPageLimit}&statusgroup=${statusGroup}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetStatus();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

// export function useGetStatus(currentPage, perPageLimit) {
//   const [allstatus, setAllstatus] = useState([]);
//   const [totalPages, setTotalPages] = useState(0); // Change to number, not array
//   const [totalCount, setTotalCount] = useState(0); // Change to number, not array
//   const [loading, setLoading] = useState(true);

//   const fetchuseGetStatus = () => {
//     setLoading(true);
//     const endpoint =
//       VisitorEndpoint.STATUS_LIST +
//       `?currentpage=${currentPage}&limit=${perPageLimit}`;
//     const axiosInstance = getAxios();

//     axiosInstance
//       .get(endpoint)
//       .then((response) => {
//         setLoading(false);
//         setTotalPages(response.data.data.totalPages);
//         setTotalCount(response.data.data.totalCount);
//         setAllstatus(response.data.data.data);
//       })
//       .catch((error) => {
//         // toast.error(error.response.data.message);;
//         setLoading(false);
//       });
//   };

//   useEffect(() => {
//     fetchuseGetStatus();
//   }, [currentPage, perPageLimit]);

//   const refetch = () => {
//     fetchuseGetStatus();
//   };

//   return { allstatus, totalPages, loading, totalCount, refetch };
// }

export function useGetAllAdmin(currentPage, perPageLimit, triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetAllAdmin = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.GET_SUBADMIN_DATA +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetAllAdmin();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useCountries() {
  const [countries, setCountries] = useState([]);

  const fetchCountries = () => {
    const endpoint = VisitorEndpoint.ALL_COUNTRY;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching countries');
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries;
}
export function useCItyByCountries() {
  const [countries, setCountries] = useState([]);

  const fetchCountries = () => {
    const endpoint = VisitorEndpoint.ALL_CITY_BY_COUNTRY;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching countries');
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries;
}
export function useStates(iso2) {
  const [states, setStates] = useState([]);
  const fetchStates = () => {
    const endpoint = VisitorEndpoint.ALL_STATE + `/${iso2}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching states');
      });
  };

  useEffect(() => {
    if (iso2) {
      fetchStates();
    }
  }, [iso2]);

  return states;
}

export function useCities(state_code) {
  const [cities, setCities] = useState([]);

  const fetchCities = () => {
    const endpoint = VisitorEndpoint.ALL_CITY + `/${state_code}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching cities');
      });
  };

  useEffect(() => {
    if (state_code) {
      fetchCities();
    }
  }, [state_code]);

  return cities;
}
export function useBrandCategory(currentPage, perPageLimit, triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBrandCategory = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ADD_BRANDCATEGORY +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrandCategory();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useInfluncersCategory(
  currentPage,
  perPageLimit,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseInfluncersCategory = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ADD_INFLUNCERCATEGORY +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseInfluncersCategory();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useCampaignRequirement(
  currentPage,
  perPageLimit,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCampaignRequirement = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_CAMPAIGNREQUIREMENT +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCampaignRequirement();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useCollbType(currentPage, perPageLimit, triggerRefresh) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseCollbType = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.COLAB_TYPE +
      `?currentpage=${currentPage}&limit=${perPageLimit}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseCollbType();
  }, [currentPage, perPageLimit, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useDetailsId(id, open) {
  const [detailsId, setDetailsId] = useState();

  const fetchuseDetailsId = () => {
    if (open) {
      const endpoint = `${VisitorEndpoint.GET_VISITORS_DETAILS_BY_ID}/${id}`;
      const axiosInstance = getAxios();

      axiosInstance
        .get(endpoint)
        .then((response) => {
          setDetailsId(response.data.data);
        })
        .catch((error) => {
          // toast.error(error.response.data.message);;
        });
    }
  };

  useEffect(() => {
    fetchuseDetailsId();
  }, [id, open]);

  return detailsId;
}
export function useCamapignProductByBrandId(id) {
  const [detailsId, setDetailsId] = useState();

  const fetchuseDetailsId = () => {
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_PRODUCT}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setDetailsId(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
      });
  };

  useEffect(() => {
    fetchuseDetailsId();
  }, [id]);

  return detailsId;
}

export function useGetTotalNumberOfCampaignByBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_TOTAL_CAMPAIGN_BY_BRAND}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalNumberOfActiveCampaignByBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_TOTAL_ACTIVE_CAMPAIGN_BY_BRAND}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalPaymentByBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_TOTAL_PAYMENT_BY_BRAND}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalPendingPaymentByBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_TOTAL_PENDING_PAYMENT_BY_BRAND}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetBrandDetails(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_BRAND_DETAILS}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetInfluncersSatgeDetails(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_INFLUNCER_STAGE_DETAILS}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetCampaignSatgeDetails(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BY_ID}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetActiveCampaignDetails(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.ALL_ACTIVE_CAMPAIGN_BY_BRAND}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalNumberparticipatedCamapign(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_PARTICIPATED_CAMPAIGN}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalNumberparActiveCamapignM(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_MTOTAL_ACTIVE_CAMPAIGN}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalNumberpaReimbursementrCamapignM(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_MREIMBURSEMENT_MODE_CAMPAIGN}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalNumberpaReimbursementPendingM(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_MREIMBURSEMENT_PENDING}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetAllActiveCampaignByM(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_ALL_ACTIVE_CAMPAIGN}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetDashboardCount() {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = VisitorEndpoint.GET_DASHBOARD_COUNT;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetDashboardPaymentCount() {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = VisitorEndpoint.GET_DASHBOARD_PAYMENT;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetDashboardReimbersmentCount() {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = VisitorEndpoint.GET_DASHBOARD_REIMBURSMENT;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetTotalPlacedOrderCampaign(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_PLACED_ORDER}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalPendingOrderCampaign(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_PENDING_ORDER}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalReviewOrderCampaign(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_REVIEW_ORDER}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalExhaustOrderCost(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_EXHAUST_ORDER_COST}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetTotalReimbursmentCampaignCost(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_PAID_REIMBURSEMENT_COST}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalCampaignCollabComplete(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_COLLAB_COMPLETED}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetTotalCampaignCollabPending(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_COLLAB_PENDING}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetPaymentReceivedFromBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_RECIVED_PAYMENT}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetCancelledCampaign(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_RECIVED_CANCELLED}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

// web registeretions
export function useGetWebInfluncersDeatils(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.GET_WEB_INFLUNCERS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetWebBrandsDeatils(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  filterParam,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.GET_WEB_BRANDS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}&${filterParam}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh,
  ]);

  return { allstatus, totalPages, loading, totalCount };
}

// charts

export function useOrderCharts(startDate, endDate) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBrandCategory = () => {
    setLoading(true);
    // const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART
    //    || VisitorEndpoint.ADMIN_ORDER_CHART +
    //   `?startdate=${startDate}&enddate=${endDate}`;
    const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART;
    if (startDate && endDate) {
      endpoint += `?startdate=${startDate}&enddate=${endDate}`;
    }
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data[0].orders);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrandCategory();
  }, [startDate, endDate]);

  return { allstatus, loading };
}
export function useCollabCharts(startDate, endDate) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBrandCategory = () => {
    setLoading(true);
    // const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART
    //    || VisitorEndpoint.ADMIN_ORDER_CHART +
    //   `?startdate=${startDate}&enddate=${endDate}`;
    const endpoint = VisitorEndpoint.ADMIN_COLLAB_CHART;
    if (startDate && endDate) {
      endpoint += `?startdate=${startDate}&enddate=${endDate}`;
    }
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data[0].collabs);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrandCategory();
  }, [startDate, endDate]);

  return { allstatus, loading };
}
export function useCampaignCollabCharts(id, startDate, endDate) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBrandCategory = () => {
    setLoading(true);
    // const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART
    //    || VisitorEndpoint.ADMIN_ORDER_CHART +
    //   `?startdate=${startDate}&enddate=${endDate}`;
    const endpoint = `${VisitorEndpoint.CAMPAIGN_COLLAB_CHART}/${id}`;
    // const endpoint = VisitorEndpoint.CAMPAIGN_COLLAB_CHART;
    if (startDate && endDate) {
      endpoint += `?startdate=${startDate}&enddate=${endDate}`;
    }
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data[0].collabs);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrandCategory();
  }, [startDate, endDate]);

  return { allstatus, loading, id };
}
export function useCampaignOrderCharts(id, startDate, endDate) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBrandCategory = () => {
    setLoading(true);
    // const endpoint = VisitorEndpoint.ADMIN_ORDER_CHART
    //    || VisitorEndpoint.ADMIN_ORDER_CHART +
    //   `?startdate=${startDate}&enddate=${endDate}`;
    const endpoint = `${VisitorEndpoint.CAMPAIGN_ORDER_CHART}/${id}`;
    if (startDate && endDate) {
      endpoint += `?startdate=${startDate}&enddate=${endDate}`;
    }
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data[0].orders);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBrandCategory();
  }, [startDate, endDate, id]);

  return { allstatus, loading };
}

export function useGetInfluncersUpiData(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_INFLUNCER_UPI}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, [id]);

  return { allstatus, loading };
}

export function useGetCampaignPendingPaymentByBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BRAND_PENDING_PAYMENT}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetCampaignByOrderProductName(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BY_ORDER_PRODUCTNAME}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetCampaignByOrderPlateformName(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.GET_CAMPAIGN_BY_ORDER_PLATFORMNAME}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}
export function useGetExportExcel(filterParam, triggerRefresh) {
  const [allstatusExport, setAllstatusExport] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCamapign = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.EXPORT_EXCEL + (filterParam ? `?${filterParam}` : "");
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatusExport(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetCamapign();
  }, [filterParam, triggerRefresh]);

  return { allstatusExport };
}
export function useGetExportExcelOrders(
  filterParam,
  sortField,
  sortOrder,
  triggerRefresh
) {
  const [allstatusExport, setAllstatusExport] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetCamapign = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.EXPORT_EXCEL_ORDERS +
      `?sortField=${sortField || ""}&sortOrder=${sortOrder || ""}&${
        filterParam || ""
      }`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatusExport(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetCamapign();
  }, [filterParam, sortField, sortOrder, triggerRefresh]);

  return { allstatusExport };
}

export function useGetCampaignAnalyticsDeatils(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.CAMAPAIGN_ANALYTICS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [currentPage, perPageLimit, sortField, sortOrder, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}
export function useGetFilter() {
  const [allstatus, setAllstatus] = useState([]);

  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint = VisitorEndpoint.ALL_FILTER;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        
        setAllstatus(response.data.data);        

      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, []);


  return { allstatus, loading };
}


export function useGetCampaignStatsforBrand(id) {
  const [allstatus, setAllstatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchuseGetPlatform = () => {
    setLoading(true);
    const endpoint = `${VisitorEndpoint.BRAND_CAMPAIGN_STATS}/${id}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setAllstatus(response.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetPlatform();
  }, []);

  return { allstatus, loading };
}

export function useGetCampaignProgressDeatils(
  currentPage,
  perPageLimit,
  sortField,
  sortOrder,
  triggerRefresh
) {
  const [allstatus, setAllstatus] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchuseGetBrand = () => {
    setLoading(true);
    const endpoint =
      VisitorEndpoint.ALL_CAMPAIGN_PROGRESS +
      `?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
        sortField || ""
      }&sortOrder=${sortOrder}`;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setLoading(false);
        setTotalPages(response.data.data.totalPages);
        setTotalCount(response.data.data.totalCount);
        setAllstatus(response.data.data.data);
      })
      .catch((error) => {
        // toast.error(error.response.data.message);;
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchuseGetBrand();
  }, [currentPage, perPageLimit, sortField, sortOrder, triggerRefresh]);

  return { allstatus, totalPages, loading, totalCount };
}

export function useGetAllOperations() {
  const [countries, setCountries] = useState([]);

  const fetchCountries = () => {
    const endpoint = VisitorEndpoint.GET_ALL_OPERATIONS;
    const axiosInstance = getAxios();

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setCountries(response.data.data.data);
      })
      .catch((error) => {
        // toast.error('Error fetching countries');
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return countries;
}
