import { Box } from "@mui/material";
import Button from "@mui/material/Button";

const SubmitButton = ({
  onClick,
  label,
  color = "#ffffff",
  background = "#163e6e",
  type,disabled
}) => {
  return (
    <Box sx={{display:"flex",justifyContent:"center"}}>
    <Button
      size="small"
      sx={{
        width:"40%",
        borderRadius: "20px",
        padding: "7px 20px",
        color: disabled ? "#aaaaaa" : color, 
        fontFamily: '"Outfit", sans-serif',
        background: disabled ? "#dddddd" : background, 
        fontSize: "20px",
        fontWeight: 500,
        "&:hover": {
          background: disabled ? "#dddddd" : background, 
          color: disabled ? "#aaaaaa" : color, 
        },
        textTransform: "none",
      }}
      type={type}
      onClick={onClick}
    >
      {label}
    </Button>
    </Box>
  );
};
export default SubmitButton;
