import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useBrandCategory,
  useCampaignRequirement,
  useGetBrand,
  useGetStatus,
  useGetWebBrandsDeatils,
  useGetWebInfluncersDeatils,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopup from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RemovePopup from "../../component/removemasterdata/removePopup";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ProductView from "../../component/viewdetails/ProductView";
import BrandViewDetails from "../../component/viewdetails/BrandViewDetails";
import ImageIcon from "../../component/CoustomImage";
import { Brand } from "../../component/IconsImport";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import FilterPopupComponent from "../../common/filter/filterPopup";
import WebMarqinfluncersView from "../../component/viewdetails/WebMarqinfluncersView";
import WebBrandsView from "../../component/viewdetails/WebBrandsView";
import { useDispatch, useSelector } from "react-redux";
import { setWebBrandFilter } from "../../store/FilterSlice";
const sortArray = [
  // {
  //   sortOrder: "asc",
  //   sortField: "brandName",
  //   sortName: "Brand Name (Asc)",
  // },
  // {
  //   sortOrder: "desc",
  //   sortField: "updatedAt",
  //   sortName: "Updated Date (Dsc)",
  // },
];

const WebBrandDetailsComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.webBrandFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const { allstatus, loading, totalPages, totalCount } = useGetWebBrandsDeatils(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,triggerRefresh
  );
  // console.log(allstatus);
  // let statusGroup = "Brand";
  // const status = useGetStatus("", "", statusGroup);
  // let filters = [
  //   {
  //     label: "Brand Category",
  //     urlParam: "brandcategory",
  //     values: brandCategories.allstatus?.map((item) => ({
  //       value: item._id,
  //       display: item.brandCategory,
  //     })),
  //   },
  //   {
  //     label: "Status",
  //     urlParam: "status",
  //     values: status.allstatus?.map((item) => ({
  //       value: item._id,
  //       display: item.status,
  //     })),
  //   },
  // ];
  const [editState, setEditState] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);

  const navigate = useNavigate();

  const brandCategories = useBrandCategory();
  const campaignRequirement = useCampaignRequirement();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "name", label: "Name", minWidth: 200 },
    { id: "number", label: "Number", minWidth: 200 },
    { id: "email", label: "Email", minWidth: 200 },
    { id: "brandname", label: "Brand Name", minWidth: 200 },
  ];
  // const perPageLimit = 9;

  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,
    name: item.fullName,
    email: item.email,
    number: item.mobileNumber,
    brandname: item.brandName,
  }));

  const dateFilters = {
    label: "Estimated startdate",
    urlParam: "estimatedcampaignstartdate",
    values: [
      { label: 'Just Exploring', value: 'Just Exploring' },
      { label: '<15 days', value: '<15 days' },
      { label: '15 to 30 days', value: '15 to 30 days' },
      { label: 'After 30 days', value: 'After 30 days' },
    ],
  };
  let filters = [
 {
    label: "Category",
    urlParam: "brandcategory",
    values: brandCategories?.allstatus.map((item) => ({
      label: item.brandCategory,
      value: item._id,
    })),
  },
{
    label: "Campaign Requirement",
    urlParam: "campaignrequirement",
    values: campaignRequirement?.allstatus.map((item) => ({
      label: item.title,
      value: item._id,
    })),
  }]
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };

  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },

    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
  ];

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
 
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
    dispatch(setWebBrandFilter(value));
    setCurrentPage(1);
    setTriggerRefresh(prev => !prev);}
  };
  const filterToggle = () => {
    setIsOpen(!isOpen);
  };
  // console.log(editState,"Note")
  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_WEB_BRANDS_DETAILS"
        onSuccess={refreshData}

      />
      <WebBrandsView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />

      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Brands", href: "/brand" },
                { name: "Brands Registerations", href: "/brand/details" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Brand Web Registerations</p>
              <span>{totalCount}&nbsp;Total Brands Registerations</span>
            </div>
            {/* <div>
              <CustomButton
                onClick={() => navigate("/marqfluencer/add")}
                label="Add Brand"
              />
            </div> */}
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="webBrandFilter" 
            />
         
            {/* <SortPopup sortFunction={selectSort} sortArray={sortArray} /> */}
          </div>
          {/* <FilterPopupComponent
            filters={filters}
            onChange={setParam}
            isOpen={isOpen}
          /> */}
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default WebBrandDetailsComponent;
