import React from "react";
import "./style.css";
import "./index.css";
import MainRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-quill/dist/quill.snow.css';
import 'react-photo-view/dist/react-photo-view.css';


function App() {
  return (
    <>
      <MainRoutes />
      <ToastContainer
        draggable
        pauseOnHover
        theme="colored"
      />

    </>
  );
}

export default App;
