import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { putJsonData } from "../../Services/AxoisInterceptorr";
import { useGetAllOperations, useGetStatus } from "../customHooks/customHook";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import SingleSelect from "../../common/select/SingleSelect";
import { validateInput } from "../validetionUtility";
import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";

const AddAmPmPopup = ({
  open,
  onClose,
  row,
  onFormSubmit,
  editState,
  endpoint,
  statusGroup,
  showStatus = true,
  onSuccess,
}) => {
  let removeEndpoint = endpoint;

  const [formData, setFormData] = useState({
    adminnote: "",
  });
  const [campaignStatus, setCamapignStatus] = useState();
  const [formsubmitted, setFormsubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErorr] = useState("");
  const getAllOperations = useGetAllOperations();




  const handleCampaignStatus = (selectedOption) => {
    if (selectedOption) {
      setCamapignStatus({
        _id: selectedOption.value,
        name: selectedOption.label,
      });
    } else {
      setCamapignStatus("");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormsubmitted(true)
    setIsLoading(true);
    const isFormValid =
    campaignStatus ;


    if (isFormValid) {
    const data = {
      orderManager : campaignStatus?._id
    };
  

    let endpoint = `${VisitorEndpoint[removeEndpoint]}/${editState?._id}`;

    putJsonData(
      endpoint,
      data,
      () => {},
      (res) => {
        if (res) {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            onClose();
            onSuccess && onSuccess();
          }
        }
        setIsLoading(false);
      },
      (error) => {
        onClose();
        toast.error(error.response.data.message);
        setIsLoading(false);
      }
    );
  } else {
    console.log("Form data is not valid.");
    setIsLoading(false);
  }
  }

  return (
  
        <>
          <Modal open={open} onClose={onClose}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                background: "#FFFFFF",
                padding: "50px",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CloseIcon
                  onClick={onClose}
                  sx={{
                    color: "red",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                />
              </div>
              <h2 className="admin_note_heading">
                Add Order Manager
              </h2>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
                {showStatus && (
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Order Manager</CustomRequiredLabel>

                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={getAllOperations?.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        value={campaignStatus}
                        onChange={handleCampaignStatus}
                        name="flavor"
                      />

                      { formsubmitted && !campaignStatus && (
                        <p className="input_text_error">
                          *Please select Order Manager.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                )}
              
              </Grid>
              <Grid item xs={12} sm={12}>
                <SmallButton
                  onClick={handleSubmit}
                  width="100%"
                  label={isLoading ? "Loading..." : "Submit"}
                  disabled={isLoading}
                />
              </Grid>
            </div>
          </Modal>
        </>

  );
};

export default AddAmPmPopup;
