export const utcToIst = (utcTimeString) => {
    // Create a new Date object with the provided UTC time string
    const utcDate = new Date(utcTimeString);
  
    // Get UTC time in milliseconds
    const utcTime = utcDate.getTime();
 
    // IST is UTC + 5 hours 30 minutes (5 * 60 * 60 * 1000 milliseconds + 30 * 60 * 1000 milliseconds)
    const istTime = new Date(utcTime + (5 * 60 * 60 * 1000) + (30 * 60 * 1000));

    // Extract IST components
    const day = String(istTime.getDate()).padStart(2, '0');
    const month = String(istTime.getMonth() + 1).padStart(2, '0'); // January is 0, so we add 1 to get the correct month
    const year = istTime.getFullYear();
    const hours = String(istTime.getUTCHours()).padStart(2, '0');
    const minutes = String(istTime.getUTCMinutes()).padStart(2, '0');



    // Format the IST date and time as desired (e.g., DD-MM-YYYY HH:MM)
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };