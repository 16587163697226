import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import { SmallButton } from "../coustombutton/SmallButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import CardComponent from "../../common/card/CardComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { NoDataImage } from "../IconsImport";
import DOMPurify from "dompurify";
import { utcToIst } from "../../common/utctimechange";

export default function CampaignView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  console.log(rowData)
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "status", label: " Current Status", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
    { id: "updatedby", label: "Updated By", minWidth: 200 },
  ];
  const renderContent = useCallback((content = '') => {
    const sanitizedBlog = DOMPurify.sanitize(content);
    const renderHTML = () => (
      <div dangerouslySetInnerHTML={{ __html: sanitizedBlog }}></div>
    );
    return renderHTML();
  }, []);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };
 
  const realData = rowData?.adminNoteHistory.map((item,index) => ({
    sno: index+1,

    adminnote: item.adminNote && item.adminNote,
    status: item.status && item.status?.status,
    updatedat:
    item.updatedAt && utcToIst(item.updatedAt),    updatedby: item.updatedBy?.name,
  }));
  const list = (
    <Box
     
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>{showDetails ? "Details" : "Notes"}</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            {showDetails && (
              <Tooltip placement="top" title="Brand History">
                <HistoryIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(true);
                    setShowDetails(false);
                  }}
                />
              </Tooltip>
            )}
            {showHistory && (
              <Tooltip placement="top" title="Brand Details">
                <VisibilityIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(false);
                    setShowDetails(true);
                  }}
                />
              </Tooltip>
            )}
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              {showHistory && (
                <>
                  <ShortingTable
                    columns={columns}
                    // loading={activeCampaignDetailsLoading}
                    data={realData}
                  />

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
              {showDetails && (
                <>
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign Name
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.campaignName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Brand Name
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.brand?.brandName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
             
  {/* <div key={index}>
    <p>Product Name: {item.productId.productName}</p>
    <p>Product Quantity: {item.productQuantity}</p>
  </div>
))} */}

{rowData?.product.length > 0 && rowData?.product.map((item, index) => (
                    <Grid key={index} item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                      <div style={{display:"flex",flexDirection:"column"}} >
                        <CustomInputLabel>Product Name
</CustomInputLabel>
                    
                      <CustomProductSubheadingh>
                        {item.productId.productName}
                        
                        
                      </CustomProductSubheadingh>
                      </div>

                      <div style={{display:"flex",flexDirection:"column"}} >

                        <CustomInputLabel>Product Quantity
</CustomInputLabel>
                     
                      <CustomProductSubheadingh>
                        {item.productQuantity}
                      </CustomProductSubheadingh>
                      </div>
                      </div>
                    </Grid>
                    ))}

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign Platform
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.campaignPlatform
                        .map((categoryObj) => categoryObj.platform)
                        .join(", ")}
                    </CustomProductSubheadingh>
                  </Grid>

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign Type
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.campaignType?.campaignType}
                    </CustomProductSubheadingh>
                  </Grid>

                 
              
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                      <div>
                        <CustomInputLabel>Campaign Brief
</CustomInputLabel>
                      </div>
                      <div style={{paddingLeft:"20px",paddingTop:"10px",      fontFamily: '"Outfit", sans-serif',
                                      fontWeight: 500,
                                      fontSize: "14px",color:"#000" }}>
                      <p> {renderContent(rowData?.campaignBrief)}</p>
                                          {/* <td>{renderContent(editorQuestionHtml)}</td> */}

                      </div>
                    </Grid>
                  
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />

                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign Start Date
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                    {
                        new Date(rowData?.campaignStartDate)
                          .toISOString()
                          .split("T")[0]
                      }
                    </CustomProductSubheadingh>
                  </Grid>
                  
           
                  {rowData?.campaignEndDate && (
                    <>
                    <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Campaign End Date
 </CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                    {
                        new Date(rowData?.campaignEndDate)
                          .toISOString()
                          .split("T")[0]
                      }
                    </CustomProductSubheadingh>
                  </Grid>
                  </>)}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>No of Content Pieces
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.noOfContent}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Service Cost Per Content
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.serviceCostPerContent}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Total Service Cost

</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.totalServiceCost}
                    </CustomProductSubheadingh>
                  </Grid>
                  
                  
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Total GST


</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.gstCost}
                    </CustomProductSubheadingh>
                  </Grid>
                  
                  
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Total Reimbursement Cost


</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.reimbursementCost}
                    </CustomProductSubheadingh>
                  </Grid>
                  
                  
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Total Campaign Cost


</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.totalCampaignCost}
                    </CustomProductSubheadingh>
                  </Grid>
                  
                  
                
                  
                  
                  {rowData?.proformaInvoiceNumber && (
                    <>
                    <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Proforma Invoice No.
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.proformaInvoiceNumber}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <CustomInputLabel>Proforma Invoice Image</CustomInputLabel>

                {/* <Avatar
                  sx={{ width: "150px", height: "150px" }}
                  src={rowData?.productImage.imageUrl}
                  alt="logo"
                /> */}
                <Avatar
                  sx={{ width: "150px", height: "150px" }}
                  src={NoDataImage}
                  alt="logo"
                />
              </Grid>
                  </>
                  )}
                
                  {rowData?.taxInvoiceNumber && (
                    <>
                    <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Tax Invoice No.
</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.taxInvoiceNumber}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <CustomInputLabel>Tax Invoice Image</CustomInputLabel>

                {/* <Avatar
                  sx={{ width: "150px", height: "150px" }}
                  src={rowData?.productImage.imageUrl}
                  alt="logo"
                /> */}
                <Avatar
                  sx={{ width: "150px", height: "150px" }}
                  src={NoDataImage}
                  alt="logo"
                />
              </Grid>
                  </>
                  )}
              
             
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": { overflow: "auto", background: "#f4faff",width:"90%", },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
