import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetBrand,
  useGetCampaign,
  useGetOrders,
  useGetPayment,
  useGetPayments,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import InfluencerDrawer from "../../component/viewdetails/PaymentView";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import RemovePopup from "../../component/removemasterdata/removePopup";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import FilterPopupComponent from "../../common/filter/filterPopup";
import HistoryIcon from '@mui/icons-material/History';
import NoteHistory from "../../component/adminhistory/NoteHistory";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentFilter } from "../../store/FilterSlice";

const sortArray = [
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
  {
    sortOrder: "desc",
    sortField: "paymentDate",
    sortName: "Payment Date (Dsc)",
  },
  {
    sortOrder: "asc",
    sortField: "paymentAmount",
    sortName: "Payment Amount: Low to High",
  },
  {
    sortOrder: "desc",
    sortField: "paymentAmount",
    sortName: "Payment Amount: High to Low",
  },
];

const PaymentComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.paymentFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [editState, setEditState] = useState();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "brandname", label: "Brand Name", minWidth: 200 },
    { id: "campaignname", label: "Campaign Name", minWidth: 200 },
    { id: "paymentamount", label: "Payment Amount", minWidth: 200 },
    { id: "paymentmode", label: "Payment Mode", minWidth: 200 },
    { id: "paymentdate", label: "Payment Date", minWidth: 200 },
  ];
  const { allstatus, loading, totalPages, totalCount } = useGetPayments(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,triggerRefresh
  );
  const brandFilter = useGetBrand();
  const camapignFilter = useGetCampaign()
  const baymentmodeFilter = useGetPayment()

  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,

    brandname: item.brand && item.brand.brandName,
    campaignname: item.campaign ? item.campaign.campaignName : "",
    paymentamount: item.paymentAmount,
    paymentdate: new Date(item.paymentDate).toISOString().split("T")[0],
    paymentmode: item.paymentMode && item.paymentMode.paymentMode,
  }));

  let filters = [
  {
    label: "Campaign Name",
    urlParam: "campaignname",
    values: camapignFilter?.allstatus.map((item) => ({
      label: item.campaignName,
      value: item._id,
    })),
  },

{
    label: "Brand Name",
    urlParam: "brandname",
    values: brandFilter?.allstatus.map((item) => ({
      label: item.brandName,
      value: item._id,
    })),
  },
{
    label: "Payment Mode",
    urlParam: "paymentmode",
    values: baymentmodeFilter?.allstatus.map((item) => ({
      label: item.paymentMode,
      value: item._id,
    })),
  }]
  const actions = [
    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/payment/edit", { state: { selectedData } });
   
      },
    },
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setHistoryDrawerOpen(true);
      },
    },
    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
  
    {
      icon: NoteAddIcon,
      
      onClick: (row) => {
       
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row)
        handleOpenModal();
      },
      
    },
  ];
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };
 
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
    dispatch(setPaymentFilter(value));
    setCurrentPage(1);
    setTriggerRefresh(prev => !prev);}
  };
  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);

  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_PAYMENT"
        onSuccess={refreshData}

      />
        <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <InfluencerDrawer
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
            <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        // onFormSubmit={/* Pass any necessary submit function here */}
        editState={editState}
        showStatus={false}
        endpoint="ADMIN_PAYMENT_NOTE"
        onSuccess={refreshData}

      />
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Payment", href: "/payment" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Payments</p>
              <span>{totalCount}&nbsp;Total Payments</span>
            </div>
            <div>
              <CustomButton
                onClick={() => navigate("/payment/add")}
                label="Add Payment"
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
             <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
        <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="paymentFilter" 

            />
     
       
         
          </div>
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
        <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default PaymentComponent;
