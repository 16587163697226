import React, { useEffect, useState } from "react";
import {
  CustomRequiredLabel,
  CustomInputLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import { postJsonData, putJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import {
  useGetCampaign,
  useGetInfluncers,
  useGetPayment,
} from "../customHooks/customHook";
import { toast } from "react-toastify";
import SubmitButton from "../coustombutton/SubmitButton";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import {Admin} from "../../common/Constant"
import { useUser } from "../../contexts/UserDataStore";

const EditReimbursement = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const reimbursementData = state?.selectedData || {};
  const [formData, setFormData] = useState({
    reimbursementvalue: {
      value: reimbursementData?.reimbursementValue || "",
      isValid: true,
    },
    transactionnumber: {
      value: reimbursementData?.transactionNumber || "",
      isValid: true,
    },
    paymentdate: {
      value: reimbursementData?.paymentDate
        ? new Date(reimbursementData?.paymentDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
  });
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [paymentModetype, setPaymentModetype] = useState("");
  const [campaignName, setcampaignName] = useState("");
  const [influncerName, setInfluncerName] = useState("");
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(true);

  const paymentMode = useGetPayment();
  const campaign = useGetCampaign();
  const influncer = useGetInfluncers();
  const navigate = useNavigate();


  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const handleInfluncer = (selectedOption) => {
    if (selectedOption) {
      setInfluncerName({
        _id: selectedOption.value,
        influencerName: selectedOption.label,
      });
    } else {
      setInfluncerName("");
    }
  };
  const handlePaymentMode = (selectedOption) => {
    if (selectedOption) {
      setPaymentModetype({
        _id: selectedOption.value,
        paymentMode: selectedOption.label,
      });
    } else {
      setPaymentModetype("");
    }
  };
  const handleCampaign = (selectedOption) => {
    if (selectedOption) {
      setcampaignName({
        _id: selectedOption.value,
        campaignName: selectedOption.label,
      });
    } else {
      setcampaignName("");
    }
  };

  const handleReimbursementEdit = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid = paymentModetype;
    if (isFormValid) {
      const editData = {
        paymentDate:
          formData.paymentdate.value ||
          new Date(reimbursementData?.paymentDate).toISOString().split("T")[0],
        reimbursementValue:
          formData.reimbursementvalue.value ||
          reimbursementData?.reimbursementValue,
        transactionNumber:
          formData.transactionnumber.value ||
          reimbursementData?.transactionNumber,
        influencer: influncerName?.influencer?._id ? influncerName?.influencer?._id : influncerName?._id,
        paymentMode: paymentModetype?.paymentMode?._id || paymentModetype?._id,
        campaign: campaignName?.campaign?._id || campaignName?._id,
      };

      const endpoint = `${VisitorEndpoint.EDIT_REIMBURSEMENT}/${reimbursementData?._id}`;
      putJsonData(
        endpoint,
        editData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/reimbursement");
            }, 2000);
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      setIsDisabledNew(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    setInfluncerName(reimbursementData);
    setcampaignName(reimbursementData);
    setPaymentModetype(reimbursementData);
    setLoading(false);
  }, [reimbursementData]);
  return (
    <div className="container_fluid_new">
      <div className="container_new">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <ActiveLastBreadcrumb
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Reimbursement", href: "/reimbursement" },
                  { name: "Edit Reimbursement", href: "/reimbursement/edit" },
                ]}
              />
            </div>
            <h1 className="have_solution_heading">Edit Reimbursement</h1>
            <form onSubmit={handleReimbursementEdit}>
              <Card sx={{ padding: "20px" }}>
                <CardContent>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Marqfluencer Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the Marqfluencer for which Reimbursement is made.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={influncer?.allstatus.map((item) => ({
                            label: item.influencerName,
                            value: item._id,
                          }))}
                          value={influncerName}
                          onChange={handleInfluncer}
                          name="flavor"
                          isDisabled={ user?.role !== Admin &&
                            reimbursementData && reimbursementData.influencer
                              ? !!reimbursementData.influencer.influencerName
                              : false
                          }
                          defaultValue={
                            influncerName && influncerName?.influencer
                              ? {
                                  label:
                                    influncerName?.influencer?.influencerName,
                                  value: influncerName?.influencer?._id,
                                }
                              : ""
                          }
                        />
                        {!reimbursementData &&
                          formSubmitted &&
                          !influncerName && (
                            <p className="input_text_error">
                              *Please select influncer name.
                            </p>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Campaign Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the campaign for which Reimbursement is made.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={campaign?.allstatus.map((item) => ({
                            label: item.campaignName,
                            value: item._id,
                          }))}
                          value={campaignName}
                          onChange={handleCampaign}
                          name="flavor"
                          isDisabled={ user?.role !== Admin &&
                            reimbursementData && reimbursementData.campaign
                              ? !!reimbursementData.campaign.campaignName
                              : false
                          }
                          defaultValue={
                            campaignName && campaignName?.campaign
                              ? {
                                  label: campaignName?.campaign?.campaignName,
                                  value: campaignName?.campaign?._id,
                                }
                              : ""
                          }
                        />
                        {!reimbursementData &&
                          formSubmitted &&
                          !campaignName && (
                            <p className="input_text_error">
                              *Please select campaign name.
                            </p>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Reimbursement Date
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the date on which Reimbursement is made.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="paymentdate"
                          type="date"
                          placeholder="Enter Payment Date"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          value={formData.paymentdate.value}
                          onChange={(e) => {
                            setValues(e, "paymentdate", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Reimbursement Mode
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the mode on which Reimbursement is made.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={paymentMode?.allstatus.map((item) => ({
                            label: item.paymentMode,
                            value: item._id,
                          }))}
                          value={paymentModetype}
                          onChange={handlePaymentMode}
                          name="flavor"
                          defaultValue={
                            paymentModetype && paymentModetype?.paymentMode
                              ? {
                                  label:
                                    paymentModetype?.paymentMode?.paymentMode,
                                  value: paymentModetype?.paymentMode?._id,
                                }
                              : ""
                          }
                        />
                        {formSubmitted && !paymentModetype && (
                          <p className="input_text_error">
                            *Please select payment Mode.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Reimbused Amount
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the amount which has been reimbursed.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="reimbursementvalue"
                          type="number" 
    onWheel={(e) => e.target.blur()}
                          placeholder="Enter Reimbused Amount"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.reimbursementvalue.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "reimbursementvalue", null);
                          }}
                        />
                        {/* {formData.firstname.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Transaction Number
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the transaction reference number for the
                        reimbursement made.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="transactionnumber"
                          type="text"
                          placeholder="Enter Transaction Number"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          value={formData.transactionnumber.value}
                          onChange={(e) => {
                            setValues(e, "transactionnumber", null);
                          }}
                        />
                        {/* {formData.firstname.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <SubmitButton
                  type={isDisabledNew ? "button":"submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />{" "}
                </CardContent>
              </Card>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default EditReimbursement;
