import empty_table from "../assets/images/nodataview/empty_table.png";
import addnote from "../assets/images/addnote.svg";
import marqetinglogosidebar from "../assets/images/logo/marqetinglogosidebar.svg";
import marqetinglogowhite from "../assets/images/logo/marqetinglogowhite.png";
import admin from "../assets/images/headericon/admin.png";
import brand from "../assets/images/headericon/brand.png";
import brandwhite from "../assets/images/headericon/brandwhite.png";
import collaboration from "../assets/images/headericon/collaboration.svg";
import dashboardwhite from "../assets/images/headericon/dashboardwhite.png";
import master from "../assets/images/headericon/master.png";
import order from "../assets/images/headericon/order.svg";
import payment from "../assets/images/headericon/payment.svg";
import paymentwhite from "../assets/images/headericon/paymentwhite.svg";
import paymentBlackHeader from "../assets/images/headericon/paymentBlackHeader.svg";
import product from "../assets/images/headericon/product.svg";
import WebRegisteration from "../assets/images/headericon/WebRegisteration.svg";
import Dashboard from "../assets/images/headericon/Dashboard.svg";
import Influencersvg from "../assets/images/headericon/Influencersvg.svg";
import Influencersvgblack from "../assets/images/headericon/Influencersvgblack.svg";
import reimbursement from "../assets/images/headericon/reimbursement.svg";

import closed from "../assets/images/logo/closed.png";
import notclosed from "../assets/images/logo/notclosed.png";

export const Notclosed = notclosed;
export const Closed = closed;
export const NoDataImage = empty_table;
export const Addnote = addnote;
export const MarqetingLogoSidebar = marqetinglogosidebar;
export const Marqetinglogowhite = marqetinglogowhite;


export const Admin = admin;
export const Paymentblack = paymentwhite;
export const DashboardSvg = Dashboard;
export const Brand = brand;
export const Collaboration = collaboration;
export const Master = master;
export const Order = order;
export const Payment = payment;
export const Reimbursement = reimbursement;
export const InfluencersvgBlack = Influencersvgblack;
export const Product = product;
export const InfluencerSvg = Influencersvg;
export const WebRegisterationImage = WebRegisteration;
export const Dashboardwhite = dashboardwhite;
export const Brandwhite = brandwhite;
export const PaymentBlackHeader = paymentBlackHeader;
