import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Dropdown } from "@mui/base/Dropdown";
import { MenuButton } from "@mui/base/MenuButton";
import { Menu } from "@mui/base/Menu";
import { MenuItem } from "@mui/base/MenuItem";

const SortPopupComponent = ({ sortFunction, sortArray }) => {
  const [sortName, setSortName] = useState("");
  const createHandleMenuClick = (item) => () => {
    // console.log(item.sortField, item.sortOrder);
    setSortName(item.sortName);
    sortFunction(item.sortField, item.sortOrder);
  };
  return (
    <Dropdown>
      {/* <Button
        size="small"
        sx={{
          borderRadius: "20px",
          padding: "7px 20px",
          color: "#163e6e",
          marginLeft: "10px",
          fontFamily: '"Outfit", sans-serif',
          background: "#ffffff",
          fontSize: "14px",
          fontWeight: 500,
          "&:hover": {
            background: "#ffffff",
            color: "#163e6e",
          },
          textTransform: "none",
        }}
      >
        Sort By
      </Button> */}
      <MenuButton className="menu_button">
        Sort By{sortName != "" && `:${sortName}`}
      </MenuButton>
      <Menu className="menu_popup">
        {sortArray.map((item, i) => {
          return (
            <MenuItem
              key={i}
              className="menu_item"
              onClick={createHandleMenuClick({
                sortOrder: item.sortOrder,
                sortField: item.sortField,
                sortName: item.sortName,
              })}
            >
              {item.sortName}
            </MenuItem>
          );
        })}
      </Menu>
    </Dropdown>
  );
};

export default SortPopupComponent;
