import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import HeaderNew from "../component/HeaderNew";

const PrivateRoute = ({ ...rest }) => {
  const token = localStorage.getItem("token");
  

  if (token) {
    return (
      <>
          <HeaderNew />
          {rest.element}
      </>
    );
  } 
  // else if (token && userRole === 'basic') {
  //   return (
  //     <>
  //       <HeaderNew>
  //         {rest.element}
  //       </HeaderNew>
  //     </>
  //   );
  // }
   else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
