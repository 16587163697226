import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetBrand,
  useGetCampaign,
  useGetCampaignAnalyticsDeatils,
  useGetOrders,
  useGetPayment,
  useGetPayments,
} from "../../component/customHooks/customHook";

import InfluencerDrawer from "../../component/viewdetails/PaymentView";

import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";

const CampaignAnalytics = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [editState, setEditState] = useState();
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 50 },
    { id: "brandname", label: "Brand", minWidth: 50 },
    { id: "campaignnamered", label: "Campaign", minWidth: 50 },
    { id: "RC", label: "RC", columnsTooltip: "Requested Orders", minWidth: 50 },
    {
      id: "TINCGST",
      label: "TINCGST",
      columnsTooltip: "Total Invoice Service + GST Cost",
      minWidth: 50,
    },
    {
      id: "TIRC",
      label: "TIRC",
      columnsTooltip: "Total Invoice Reimbursement Cost",
      minWidth: 50,
    },

    {
      id: "TICC",
      label: "TICC",
      columnsTooltip: "Total Invoice Campaign Cost",
      minWidth: 50,
    },
    {
      id: "TRC",
      label: "TRC",
      columnsTooltip: "Total Received Cost",
      minWidth: 50,
    },
    {
      id: "TCO",
      label: "TCO",
      columnsTooltip: "Total Completed Orders",
      minWidth: 50,
    },
    {
      id: "COSCGST",
      label: "COSCGST",
      columnsTooltip: "Completed Orders Service Cost + GST Cost",
      minWidth: 50,
    },
    {
      id: "COEC",
      label: "COEC",
      columnsTooltip: "Completed Orders Exhausted Cost",
      minWidth: 50,
    },
    {
      id: "BOR",
      label: "BOR",
      columnsTooltip: "Balance on Receieved",
      minWidth: 50,
    },
    {
      id: "BOCOS",
      label: "BOCOS",
      columnsTooltip: "Balance on Completed Orders Service",
      minWidth: 50,
    },
  ];
  const { allstatus, loading, totalCount } = useGetCampaignAnalyticsDeatils(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder
  );
  // {
  //   _id: '662b8c77f7df60b1a2e4fb53',
  //   exhaustedCost: 28660,
  //   totalGstCost: 0,
  //   serviceCostPerContent: 0,
  //   totalServiceCost: 0,
  //   reimbursementCost: 0,
  //   totalCampaignCost: 0,
  //   campaignName: 'Prezerve Review Campaign (Apr-24)',
  //   noOfContent: 225,
  //   brand: 'Prezerve',
  //   serviceCost: 0,
  //   orderCount: 70,
  //   gstCostValue: 0,
  //   totalPaymentAmount: 5400,
  //   balanceOnRecieved: -23260,
  //   balanceIncludingService: -23260,
  //   sno: 1
  // },
  const realData = allstatus.map((item) => {
    let totalServiceCost = item.totalServiceCost ? item.totalServiceCost : 0;
    let totalGstCost = item.totalGstCost ? item.totalGstCost : 0;
   let servicecost= item.serviceCost ? item.serviceCost : 0;
   let   gstcost=item.gstCostValue ? item.gstCostValue : 0;

    return {
      id: item._id,
      sno: item.sno,
      brandname: item.brand && item.brand,
      campaignnamered: item.campaignName,
      RC: item.noOfContent,
      TINCGST: totalServiceCost + totalGstCost,
      TIRC:  item.reimbursementCost ,
      TICC:item.totalCampaignCost,
      TRC: item.totalPaymentAmount,
      TCO: item.orderCount,
      servicecostper: item.serviceCostPerContent,
      COSCGST:servicecost+gstcost,
      COEC: item.exhaustedCost,
      BOR: item.balanceOnRecieved,
      BOCOS: item.balanceIncludingService,
    };
  });

  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };

  return (
    <>
      <InfluencerDrawer
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />

      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Pending Payments", href: "/pendingpayments" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Pending Payments</p>
              <span>{totalCount}&nbsp;Total Pending Payments</span>
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={false}
            isScroll={true}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default CampaignAnalytics;
