import React, { useState } from "react";
import { PATTERNS } from "../validetion";
import { Button, Card, CardContent, FormControl, Grid } from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useCamapignProductByBrandId,
  useCollbType,
  useGetBrand,
  useGetCampaign,
  useGetCampaignType,
  useGetInfluncers,
  useGetInfluncersUpiData,
  useGetPayment,
  useGetPlatform,
  useGetProduct,
  useGetStatus,
} from "../customHooks/customHook";
import { postFormData, postJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { SmallButton } from "../coustombutton/SmallButton";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

const AddCollab = () => {
  const [formData, setFormData] = useState({
    camapignname: {
      value: "",
      isValid: true,
    },
    couriername: {
      value: "",
      isValid: true,
    },
    deliverydate: {
      value: "",
      isValid: true,
    },
    colabvalue: {
      value: "",
      isValid: true,
    },
    producttrackingno: {
      value: "",
      isValid: true,
    },
    upiid: {
      value: "",
      isValid: true,
    },
    upiholdername: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [allBrand, setallBrand] = useState("");
  const [collabStatus, setcollabStatus] = useState("");
  const [productName, setProductName] = useState("");
  const [influncerName, setInfluncerName] = useState("");
  const [collabPlatform, setcollabPlatform] = useState("");
  const [collabPlatformDeliverbles, setcollabPlatformDeliverbles] = useState("");
  const [collabTypeName, setcollabTypeName] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [paymentModeName, setPaymentModeName] = useState("");
  const [upiIdName, setUpiIdName] = useState("");
  const [upiHolderNameSelect, setUpiHolderNameSelect] = useState("");
  const [deliverables, setDeliverables] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
  const [addupiHolderName, setAddupiHolderName] = useState(false);
  const [addupiId, setAddupiId] = useState(false);
  const brandDetails = useGetBrand();
  const [error, setError] = useState("");

  const productDetails = useCamapignProductByBrandId(allBrand);

  const platform = useGetPlatform();
  const influncers = useGetInfluncers();
  const collabType = useCollbType();
  const campaign = useGetCampaign();
  const paymentMode = useGetPayment();
  const upiData = useGetInfluncersUpiData(influncerName);
 

  const addDeliverable = () => {
    setDeliverables((prevDeliverables) => [
      ...prevDeliverables,
      {
        deliverablesType: "",
        deliverablesUrl: "",
        numberOfViews: 0,
        numberOfLikes: 0,
        numberOfShare: 0,
        numberOfComment: 0,
        numberOfSave: 0,
        deliverablesPlatform: null,

      },
    ]);
    setShowAddButton(false);
  };
  const handlePaymentMode = (selectedOption) => {
    if (selectedOption) {
      setPaymentModeName({
        _id: selectedOption.value,
        paymentMode: selectedOption.label,
      });
    } else {
      setPaymentModeName("");
    }
  };
  const handleUpiId = (selectedOption) => {
    if (selectedOption) {
      setUpiIdName({
        upiId: selectedOption.value,
        upiId: selectedOption.label,
      });
    } else {
      setUpiIdName("");
    }
  };

  const handleUpiHoldername = (selectedOption) => {
    if (selectedOption) {
      setUpiHolderNameSelect({
        holderName: selectedOption.value,
        holderName: selectedOption.label,
      });
    } else {
      setUpiHolderNameSelect("");
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    let regEx;
    if (name === "deliverablesUrl") {
      regEx = PATTERNS.WEBSITE_URL;
    }
    let isValid = true;
    if (value.trim() !== "") {
      if (regEx) {
        isValid = regEx.test(value);
      }
    } else {
      setError("");
    }

    if (!isValid) {
      setError(`Invalid input for ${name}`);
    } else {
      setError("");
      setDeliverables((prevDeliverables) => {
        const list = [...prevDeliverables];
        list[index][name] = { value: value, isValid: isValid };
        return list;
      });
    }
  };

  const removeDeliverable = (index) => {
    const newDeliverables = [...deliverables];
    newDeliverables.splice(index, 1);
    setDeliverables(newDeliverables);

    if (newDeliverables.length === 0) {
      setShowAddButton(true);
    }
  };

  const handleCollabType = (selectedOption) => {
    if (selectedOption) {
      setcollabTypeName(selectedOption.value);
    } else {
      setcollabTypeName("");
    }
  };
  const handleCampaignName = (selectedOption) => {
    if (selectedOption) {
      setCampaignName(selectedOption.value);
    } else {
      setCampaignName("");
    }
  };
  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      const values = selectedOption.map((option) => option.value);

      setcollabPlatform(values);
    } else {
      setcollabPlatform("");
    }
  };
  // const handleDelivereblesPlatform = (selectedOption) => {
  //   if (selectedOption) {

  //     setcollabPlatform(selectedOption.value);
  //   } else {
  //     setcollabPlatformDeliverbles("");
  //   }
  // };

  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand(selectedOption.value);
    } else {
      setallBrand("");
    }
  };
  const handleInfluncer = (selectedOption) => {
    if (selectedOption) {
      setInfluncerName(selectedOption.value);
    } else {
      setInfluncerName("");
    }
  };
  const handleProductName = (selectedOption) => {
    if (selectedOption) {
      setProductName(selectedOption.value);
    } else {
      setProductName("");
    }
  };

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const toggleAddUpiId = () => {
    setAddupiId(!addupiId);
  };
  const toggleAddUpiHolderName = () => {
    setAddupiHolderName(!addupiHolderName);
  };
  const handleOrder = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    if (!(upiIdName?.upiId || formData?.upiid?.value) && (upiHolderNameSelect?.holderName || formData?.upiholdername?.value)) {
      toast.error("Please select UPI ID before entering the Holder Name");
      setIsDisabledNew(false);
      return;
    }
    
    if ((upiIdName?.upiId || formData?.upiid?.value) && !(upiHolderNameSelect?.holderName || formData?.upiholdername?.value)) {
      toast.error("Please select UPI Holder Name");
      setIsDisabledNew(false);
      return;
    }
    const isFormValid = formData.couriername.isValid;

    // formData.companyname.isValid &&
    // formData.companyaddress.isValid &&
    // formData.companygst.isValid &&
    // formData.companypan.isValid &&
    // formData.brandwebsite.isValid &&
    // formData.brandname.value.trim() !== "" &&
    // formData.brandpocname.value.trim() !== "" &&
    // formData.phone.value.trim() !== "" &&
    // formData.brandwebsite.value.trim() !== "";
    if (isFormValid) {
      const deliverablesData = deliverables.map((deliverable) => ({
        deliverablesType: deliverable.deliverablesType.value,
        deliverablesUrl: deliverable.deliverablesUrl.value,
        numberOfViews: deliverable.numberOfViews.value,
        numberOfLikes: deliverable.numberOfLikes.value,
        numberOfShare: deliverable.numberOfShare.value,
        numberOfComment: deliverable.numberOfComment.value,
        numberOfSave: deliverable.numberOfSave.value,
      }));

      const data = {
        courierName: formData.couriername.value,
        deliveryDate: formData.deliverydate.value,
        colabValue: formData.colabvalue.value,
        productTrackingNumber: formData.producttrackingno.value,
        colabType: collabTypeName,
        colabPlatform: collabPlatform,
        influencer: influncerName,
        campaign: campaignName,
        status: collabStatus,
        brand: allBrand,
        product: productName,
        deliverables: deliverablesData,
        upiId: upiIdName?.upiId ? upiIdName?.upiId : formData.upiid.value,
      holderName: upiHolderNameSelect?.holderName
        ? upiHolderNameSelect?.holderName
        : formData.upiholdername.value,
        paymentMode: paymentModeName?._id,
      };

      postJsonData(
        VisitorEndpoint.ADD_COLLAB,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/collab");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setFormSubmitted(false);
    }
  };

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          {" "}
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Collab", href: "/collab" },
              { name: "Add Collab", href: "/collab/add" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Add Collab</h1>
        <form onSubmit={handleOrder}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the brand for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={brandDetails?.allstatus.map((item) => ({
                        label: item.brandName,
                        value: item._id,
                      }))}
                      value={allBrand}
                      onChange={handleBrand}
                      name="flavor"
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Campaign Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the campaign for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={campaign?.allstatus.map((item) => ({
                        label: item.campaignName,
                        value: item._id,
                      }))}
                      value={campaignName}
                      onChange={handleCampaignName}
                      name="flavor"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Marqfluencer Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the Marqfluencer for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={influncers?.allstatus.map((item) => ({
                        label: item.influencerName,
                        value: item._id,
                      }))}
                      value={influncerName}
                      onChange={handleInfluncer}
                      name="flavor"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Product Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the product for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={productDetails?.map((item) => ({
                        label: item.productName,
                        value: item._id,
                      }))}
                      value={productName}
                      onChange={handleProductName}
                      name="flavor"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Collab Type
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the type for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={collabType?.allstatus.map((item) => ({
                        label: item.colabType,
                        value: item._id,
                      }))}
                      value={collabTypeName}
                      onChange={handleCollabType}
                      name="flavor"
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Collab Platform
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the platform for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={platform?.allstatus.map((item) => ({
                        label: item.platform,
                        value: item._id,
                      }))}
                      value={collabPlatform}
                      onChange={handlePlatform}
                      name="flavor"
                      isMulti={true}
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Collab Value
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the value for the collab.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="colabvalue"
                      type="text"
                      placeholder="Enter Collab Value"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "colabvalue", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                    Product Tracking No.
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the shipping tracking no. of the product.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="producttrackingno"
                      type="text"
                      placeholder="Enter Product Tracking No."
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "producttrackingno", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel> Courier Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the shipping courier name.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="couriername"
                      type="text"
                      placeholder="Enter Courier Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "couriername", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Delivery Date</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the date of product delivery.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="deliverydate"
                      type="date"
                      placeholder="Enter Delivery Date"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "deliverydate", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Deliverables</CustomRequiredLabel>
                  <CustomSubheadingh>Add deliverables..</CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {showAddButton && (
                    <SmallButton
                      onClick={addDeliverable}
                      type="button"
                      label="Add Deliverables"
                    />
                  )}
                </Grid>
              </Grid>
              {deliverables.map((deliverable, i) => (
                <>
                  <Grid key={i}>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Deliverables Type
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Deliverables Type.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="deliverablesType"
                            type="text"
                            placeholder="Enter Deliverables Type"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i, null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Deliverables URL
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Deliverables URL.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="deliverablesUrl"
                            type="text"
                            placeholder="Enter Deliverables Url"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i);
                            }}
                          />
                          {error && <p className="input_text_error">{error}</p>}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Number Of Views
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Number Of Views.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="numberOfViews"
                            type="number" 
    onWheel={(e) => e.target.blur()}
                            placeholder="Enter Number Of Views"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i, null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Number Of Likes
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Number Of Likes.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="numberOfLikes"
                            type="number" 
    onWheel={(e) => e.target.blur()}
                            placeholder="Enter Number Of Likes"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i, null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Number Of Share
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Number Of Share.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="numberOfShare"
                            type="number" 
    onWheel={(e) => e.target.blur()}
                            placeholder="Enter Number Of Share"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i, null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Number Of Comment
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Number Of Comment.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="numberOfComment"
                            type="number" 
    onWheel={(e) => e.target.blur()}
                            placeholder="Enter Number Of Comment"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i, null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Number Of Save
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter Number Of Save.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl
                          sx={{ width: "100%", marginRight: "10px" }}
                        >
                          <DescriptionInput
                            name="numberOfSave"
                            type="number" 
    onWheel={(e) => e.target.blur()}
                            placeholder="Enter Number Of Save"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              handleInputChange(e, i, null);
                            }}
                          />
                        </FormControl>

                        <SmallButton
                          onClick={() => removeDeliverable(i)}
                          label="Remove"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ))}
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  paddingBottom: "15px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {deliverables.length > 0 && (
                  <SmallButton onClick={addDeliverable} label="Add More" />
                )}
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Payment Mode</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the mode of payment used in the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={paymentMode?.allstatus.map((item) => ({
                        label: item.paymentMode,
                        value: item._id,
                      }))}
                      value={paymentModeName}
                      onChange={handlePaymentMode}
                      name="flavor"
                    />
                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the UPI details if the payment mode is UPI.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sm={7}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={upiData?.allstatus[0]?.upiData.map((item) => ({
                        label: item.upiId,
                        value: item.upiId,
                      }))}
                      value={upiIdName}
                      onChange={handleUpiId}
                      name="flavor"
                    />

                    {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={1}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={toggleAddUpiId}
                >
                  <PersonAddAltIcon
                    sx={{ color: "#163e6e", width: "30px", height: "30px" }}
                  />
                </Grid>
              </Grid>
              {addupiId && !upiIdName?.upiId && (
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the UPI details if the payment mode is UPI.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="totalservicecost"
                        type="upiid"
                        placeholder="Enter UPI Id"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={(e) => {
                          setValues(e, "upiid", null);
                        }}
                      />
                      {/* {formData.upiid.isValid || (
                        <p className="input_text_error">
                          *Please enter your valid UPI Id.
                        </p>
                      )} */}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel
                    required={upiIdName?.upiId || formData?.upiid?.value}
                  >
                    UPI Holder Name
                  </CustomRequiredLabel>

                  <CustomSubheadingh>
                    Select the UPI holder name if the payment mode is UPI.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sm={7}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={upiData?.allstatus[0]?.upiData.map((item) => ({
                        label: item.holderName,
                        value: item.holderName,
                      }))}
                      value={upiHolderNameSelect}
                      onChange={handleUpiHoldername}
                      name="flavor"
                    />
                    {(upiIdName?.upiId || formData.upiid.value) &&
                      !upiHolderNameSelect &&
                      !formData.upiholdername.value && (
                        <p className="input_text_error">
                          *Please select UPI Holder Name.
                        </p>
                      )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={1}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={toggleAddUpiHolderName}
                >
                  <PersonAddAltIcon
                    sx={{ color: "#163e6e", width: "30px", height: "30px" }}
                  />
                </Grid>
              </Grid>
              {addupiHolderName && !upiHolderNameSelect?.holderName && (
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel
                      required={
                        upiIdName?.upiId || formData?.upiholdername?.value
                      }
                    >
                      UPI Holder Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the UPI holder name if the payment mode is UPI.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="upiholdername"
                        type="text"
                        placeholder="Enter UPI Holder Name"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={(e) => {
                          setValues(e, "upiholdername", null);
                        }}
                      />
                      {(upiIdName?.upiId || formData.upiid.value) &&
                        !formData.upiholdername.value &&
                        !upiHolderNameSelect?.holderName && (
                          <p className="input_text_error">
                            *Please enter UPI Holder Name.
                          </p>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <SubmitButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
              />{" "}
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default AddCollab;
