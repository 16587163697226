import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useBrandCategory,
  useGetBrand,
  useGetStatus,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopup from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RemovePopup from "../../component/removemasterdata/removePopup";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import ProductView from "../../component/viewdetails/ProductView";
import BrandViewDetails from "../../component/viewdetails/BrandViewDetails";
import ImageIcon from "../../component/CoustomImage";
import { Brand } from "../../component/IconsImport";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import HistoryIcon from "@mui/icons-material/History";
import BrandNoteHistory from "../../component/adminhistory/NoteHistory";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import FilterPopupComponent from "../../common/filter/filterPopup";
import { useSelector, useDispatch } from 'react-redux';
import { setBrandFilter, setFilterParam } from "../../store/FilterSlice";

const sortArray = [
  {
    sortOrder: "asc",
    sortField: "brandName",
    sortName: "Brand Name (Asc)",
  },
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
];

const BrandComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.brandFilter);

  const [currentPage, setCurrentPage] = useState(1);
  // const [filterParam, setFilterParam] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [sortField, setSortField] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const [sortOrder, setSortOrder] = useState("");
  const { allstatus, loading, totalPages, totalCount } = useGetBrand(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh
  );
  const brandNameFilter = useGetBrand();
  const brandCategories = useBrandCategory();
  let statusGroup = "Brand";
  const status = useGetStatus("", "", statusGroup);
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  let filters = [
    {
      label: "Brand Name",
      urlParam: "brandname",
      values: brandNameFilter.allstatus.map((item) => ({
        label: item.brandName,
        value: item._id,
      })),
    },
    {
      label: "Status",
      urlParam: "status",
      values: status.allstatus.map((item) => ({
        label: item.status,
        value: item._id,
      })),
    },
    {
      label: "Category",
      urlParam: "brandcategory",
      values: brandCategories?.allstatus.map((item) => ({
        label: item.brandCategory,
        value: item._id,
      })),
    },
  ];

  // console.log(brandCategories);
  // console.log(status.allstatus);
  const [editState, setEditState] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "brandname", label: "Brand Name", minWidth: 200 },
    { id: "brandcategory", label: "Brand Category", minWidth: 200 },
    // { id: "companylegalname", label: "Company Name", minWidth: 200 },
    { id: "status", label: "Status", minWidth: 200 },
    { id: "lastLoggedIn", label: "Last LoggedIn", minWidth: 200 },
  ];
  // const perPageLimit = 9;

  const realData = allstatus.map((item) => ({
    sno: item.sno,

    id: item._id,
    brandname: item.brandName,
    brandcategory: item.brandCategory && item.brandCategory?.brandCategory,
    // companylegalname: item.companyLegalName,
    status: item.status && item.status?.status,
    lastLoggedIn: item.lastLoggedIn && item.lastLoggedIn,
  }));
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  // const setParam = (value) => {
  //   if (filterParam !== value) {
  //     setFilterParam(value);
  //     setCurrentPage(1);
  //     setTriggerRefresh((prev) => !prev);
  //   }
  // };
  const setParam = (value) => {
    if (filterParam !== value) {
    dispatch(setBrandFilter(value));
    setCurrentPage(1);
    setTriggerRefresh(prev => !prev);}
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  // useEffect(() => {
   
  //   console.log("Current filter param:", filterParam);

  //   // Yaha par aap kuch logic implement kar sakte hain jo tab execute hoga jab filterParam change hoga
  // }, [filterParam]);  // Dependancy array mein filterParam ko add karna yeh ensure karta hai ki har filterParam change par yeh code execute hoga.

  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setHistoryDrawerOpen(true);
      },
    },
    {
      icon: SignalCellularAltIcon,
      onClick: (row) => navigate(`/brand/stats/${row.id}`),
    },
    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/brand/edit", { state: { selectedData } });
       
      },
    },

    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
    {
      icon: NoteAddIcon,

      onClick: (row) => {

        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row);
        handleOpenModal();
      },
    },
  ];

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  const filterToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_BRAND"
        onSuccess={refreshData}
      />
      <BrandViewDetails
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        statusGroup="Brand"
        // onFormSubmit={/* Pass any necessary submit function here */}
        editState={editState}
        endpoint="ADMIN_BRAND_NOTE"
        onSuccess={refreshData}
      />
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Brands", href: "/brand" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Brands</p>
              <span>{totalCount}&nbsp;Total Brands</span>
            </div>
            <div>
              <CustomButton
                onClick={() => navigate("/brand/add")}
                label="Add Brand"
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SortPopup sortFunction={selectSort} sortArray={sortArray} />

            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="brandFilter" 
            />
          </div>

          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
            userNone={true}
          />
         <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default BrandComponent;
