import React from "react";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import CardComponent from "../../common/card/CardComponent";
import PaidIcon from "@mui/icons-material/Paid";
import {
  useCollabCharts,
  useGetDashboardCount,
  useGetDashboardPaymentCount,
  useGetDashboardReimbersmentCount,
  useOrderCharts,
} from "../../component/customHooks/customHook";
import { useUser } from "../../contexts/UserDataStore";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import {
  InfluencerSvg,
  InfluencersvgBlack,
  NoDataImage,
  PaymentBlackHeader,
  Paymentblack,
  Reimbursement,
  WebRegisterationImage,
} from "../../component/IconsImport";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Girl, Loyalty } from "@mui/icons-material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import { Card, CardContent, Grid } from "@mui/material";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const DashboardComponent = () => {
  const { user } = useUser();
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const { allstatus: totalCampaign, loading: totalCampaignLoading } =
    useGetDashboardCount();
  const { allstatus: totalPaymentCount, loading: totalPaymentCountLoading } =
    useGetDashboardPaymentCount();
  const {
    allstatus: totalReimbersmentCount,
    loading: totalReimbersmentCountLoading,
  } = useGetDashboardReimbersmentCount();
  // console.log(totalCampaign)
  // console.log(totalPaymentCount)
  // console.log(totalReimbersmentCount)
  const orderChart = useOrderCharts();
  const collabChart = useCollabCharts();

  // if (user?.role === 'Operations') {
  //   return (
  //     <div style={{ overflow:"hidden", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       <h1 style={{color:"#163e6e"}}>You are not authorized for this.</h1>
  //     </div>
  //   );
  // }

  return (
    <>

          <div className="container_fluid_new">
            <div className="container_new">
              <div>
                <ActiveLastBreadcrumb
                  links={[{ name: "Dashboard", href: "/" }]}
                />
              </div>
              <div className="brands_container">
                <div className="brands_content">
                  <p>
                    Hello{" "}
                    {user &&
                      user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                  </p>
                </div>
              </div>
              <div className="card_container">
                <CardComponent
                  logoColor="purple"
                  icon={LoyaltyIcon}
                  data={totalCampaign?.totalBrandCount}
                  heading="Brands"
                  loading={totalCampaignLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="blue"
                  image={InfluencersvgBlack}
                  data={totalCampaign?.totalInfluencerCount}
                  heading="Marqfluencers"
                  loading={totalCampaignLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="green"
                  icon={CampaignIcon}
                  data={totalCampaign?.totalCampaignCount}
                  heading="Campaigns"
                  loading={totalCampaignLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="purple"
                  icon={CampaignIcon}
                  data={totalCampaign?.totalActiveCampaignCount}
                  heading="Active Campaigns"
                  loading={totalCampaignLoading}
                  width="250px"
                />
                {/* <CardComponent
              logoColor="purple"
              icon={PaidIcon}
              data={totalCampaign?.totalOrderCount}
              heading="Orders"
              loading={false}
              width="250px"

            /> */}
                <CardComponent
                  logoColor="blue"
                  icon={Loyalty}
                  data={totalCampaign?.totalBrandDetailsCount}
                  heading="Web Registered Brands"
                  loading={totalCampaignLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="green"
                  image={WebRegisterationImage}
                  data={totalCampaign?.totalInfluencerDetailsCount}
                  heading="Web Registered Marqfluencers"
                  loading={totalCampaignLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="purple"
                  image={PaymentBlackHeader}
                  data={totalPaymentCount?.totalPaymentRecieved}
                  heading="Received Payment"
                  loading={totalPaymentCountLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="blue"
                  image={PaymentBlackHeader}
                  data={totalPaymentCount?.pendingPayment}
                  heading="Pending Payment"
                  color={
                    totalPaymentCount?.pendingPayment > 0 ? "green" : "red"
                  }
                  loading={totalPaymentCountLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="green"
                  image={Reimbursement}
                  data={
                    totalReimbersmentCount?.totalReimbursementPayed
                      ?.totalReimbursementValue
                  }
                  heading="Payout Reimbursed"
                  loading={totalReimbersmentCountLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="purple"
                  image={Reimbursement}
                  data={
                    totalReimbersmentCount?.totalReimbursementPending
                      ?.total_pending_reimbursement
                  }
                  heading="Payout Pending"
                  color={
                    totalReimbersmentCount?.totalReimbursementPending
                      ?.total_pending_reimbursement > 0
                      ? "green"
                      : "red"
                  }
                  loading={totalReimbersmentCountLoading}
                  width="250px"
                />

                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                    <Card sx={{ width: "100%" }}>
                      <CardContent>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker />
    </LocalizationProvider> */}
                        <ResponsiveContainer width="100%" height={300}>
                          {orderChart?.allstatus &&
                          orderChart?.allstatus.length > 0 ? (
                            <LineChart
                              data={orderChart?.allstatus}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="date" stroke="#000000" />
                              <YAxis stroke="#000000" />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="totalOrders"
                                stroke="#163e6e"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart>
                          ) : (
                            <div>
                              <CustomRequiredLabel>
                                No Data Available
                              </CustomRequiredLabel>
                              <img src={NoDataImage} />
                            </div>
                          )}
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                    <Card>
                      <CardContent>
                        <ResponsiveContainer width="100%" height={300}>
                          {collabChart?.allstatus &&
                          collabChart?.allstatus.length > 0 ? (
                            <BarChart
                              data={collabChart?.allstatus}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid
                                strokeDasharray="5 5"
                                stroke="#163e6e"
                                strokeWidth={0.5}
                              />
                              <XAxis dataKey="date" stroke="#000000" />
                              <YAxis stroke="#000000" />
                              <Tooltip />
                              <Legend />
                              <Bar
                                dataKey="totalCollabs"
                                fill="#163e6e"
                                barSize={30}
                              />
                            </BarChart>
                          ) : (
                            <div>
                              <CustomRequiredLabel>
                                No Data Available
                              </CustomRequiredLabel>
                              <img src={NoDataImage} />
                            </div>
                          )}
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/* </div> */}
          </div>
    
    </>
  );
};
export default DashboardComponent;
