import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

const CoustomButtonName = ({
  onClick,
  label,
  color = "#163e6e",
  background = "#ffffff",
  type
}) => {
  return (
    <Button
      size="small"
      sx={{
        borderRadius: "20px",
        padding: "7px 20px",
        color: color,
        fontFamily: '"Outfit", sans-serif',
        background: background,
        fontSize: "14px",
        fontWeight: 500,
        "&:hover": {
          background: background,
          color: color,
        },
        textTransform: "none",
      }}
      type={type}
      onClick={onClick}
    >

      {label}
    </Button>
  );
};
export default CoustomButtonName;
