  import React, { useEffect, useState } from "react";
import StickyHeadTableadmin from "../component/subadminTabel";



const SubAdminComponent = () => {

 

  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
      <StickyHeadTableadmin/>
        </div>
      </div>
    </>
  );
};

export default SubAdminComponent;
