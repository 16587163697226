import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import {
  patchFormNewData,
  putJsonData,
} from "../../Services/AxoisInterceptorr";
import { useGetStatus } from "../customHooks/customHook";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import SingleSelect from "../../common/select/SingleSelect";
import { validateInput } from "../validetionUtility";
import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";

const AddReviewPopup = ({
  open,
  onClose,
  row,
  onFormSubmit,
  editState,
  endpoint,
  showStatus = true,
  onSuccess,
}) => {
  let removeEndpoint = endpoint;

  const [selectedImages, setSelectedImages] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [selectedDeliveredImages, setSelectedDeliveredImages] = useState([]);

  const [error, setErorr] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid = selectedImages?.length > 0;

    if (isFormValid) {
      const data = {};
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      if (selectedImages) {
        selectedImages.forEach((file, index) => {
          newData.append("reviewScreenshot", file.file);
        });
      }
      if (selectedDeliveredImages) {
        selectedDeliveredImages.forEach((file, index) => {
          newData.append("deliveredScreenshot", file.file);
        });
      }
      let endpoint = `${VisitorEndpoint[removeEndpoint]}/${editState?._id}`;

      patchFormNewData(
        endpoint,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              onClose();
              onSuccess && onSuccess();
              setSelectedImages([]);
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          // onClose();
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
  const handleReviewScreenShot = (e) => {
    setSelectedImages([
      ...selectedImages,
      ...Array.from(e.target.files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      })),
    ]);
    e.target.value = null;
  };

  const removeImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handledeliveredScreenShot = (e) => {
    setSelectedDeliveredImages([
      ...selectedDeliveredImages,
      ...Array.from(e.target.files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      })),
    ]);
    e.target.value = null;
  };

  const removeDeliveredImage = (indexToRemove) => {
    setSelectedDeliveredImages(
      selectedDeliveredImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  return (
    <>
      {" "}
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <Modal open={open} onClose={onClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            background: "#FFFFFF",
            padding: "50px",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CloseIcon
              // onClick={onClose}
              onClick={() => {
                setSelectedImages([]);
                onClose();
              }}
              sx={{
                color: "red",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            />
          </div>
          <div style={{ overflow: "auto", maxHeight: "60vh", padding: "20px" }}>

          <h2 className="admin_note_heading">Add Review</h2>
          <form onSubmit={handleSubmit}>
            <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: "20px", marginTop: "20px" }}
            >
              <CustomRequiredLabel>Review Screenshot</CustomRequiredLabel>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  paddingBottom: "15px",
                  alignItems: "center",
                  // display: "flex",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleReviewScreenShot}
                    multiple
                    className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                  />

                  {formSubmitted && !selectedImages.length > 0 && (
                    <p className="input_text_error">
                      *Please upload the review screenshot.
                    </p>
                  )}
                </FormControl>
                <div className="mt-5 flex flex-row flex-wrap  justify-between gap-2">
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      className="flex justify-between flex-row items-center border-gray-300 border-[1px] rounded-lg w-[30%]"
                    >
                      <div className="p-1 flex flex-col">
                        <CloseIcon
                          onClick={() => removeImage(index)}
                          className="text-red-500 cursor-pointer ml-2 self-end"
                        />
                        <img
                          src={image.url}
                          alt={`Selected ${index}`}
                          className="w-32 h-32 object-cover  border border-gray-300"
                          onClick={() => handleOpenZoomModal(image.url)}
                        />
                        {/* <p className="ml-2">{image.file.name}</p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ marginBottom: "20px", marginTop: "20px" }}
            >
              <CustomRequiredLabel>Delivered & Product Screenshot</CustomRequiredLabel>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  paddingBottom: "15px",
                  alignItems: "center",
                  // display: "flex",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handledeliveredScreenShot}
                    multiple
                    className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                  />

                  {/* {formSubmitted && !selectedDeliveredImages.length > 0 && (
                    <p className="input_text_error">
                    *Please upload the Delivered & Product Screenshot screenshot.
                    </p>
                  )} */}
                </FormControl>
                <div className="mt-5 flex flex-row flex-wrap  justify-between gap-2">
                  {selectedDeliveredImages.map((image, index) => (
                    <div
                      key={index}
                      className="flex justify-between flex-row items-center border-gray-300 border-[1px] rounded-lg w-[30%]"
                    >
                      <div className="p-1 flex flex-col">
                        <CloseIcon
                          onClick={() => removeDeliveredImage(index)}
                          className="text-red-500 cursor-pointer ml-2 self-end"
                        />
                        <img
                          src={image.url}
                          alt={`Selected ${index}`}
                          className="w-32 h-32 object-cover  border border-gray-300"
                          onClick={() => handleOpenZoomModal(image.url)}
                        />
                        {/* <p className="ml-2">{image.file.name}</p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
            
            <Grid item xs={12} sm={12}>
              <SmallButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
                width="100%"
              />
            </Grid>
          </form>

        </div>
        </div>
      </Modal>
    </>
  );
};

export default AddReviewPopup;
