import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import { SmallButton } from "../coustombutton/SmallButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import CardComponent from "../../common/card/CardComponent";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function WebMarqinfluncersView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const columns = [
    { id: "socialplatform", label: "Social Platform", minWidth: 200 },
    {
      id: "profileurl",
      label: "profile Url",
      minWidth: 200,
      align: "left",
      format: (value) => value,
    },
    {
      id: "followerscount",
      label: "Followers Count",
      minWidth: 200,
      align: "left",
      format: (value) => value,
    },
  ];

  function createData(socialplatform, profileurl, followerscount) {
    return { socialplatform, profileurl, followerscount };
  }
  const rows =
    rowData &&
    rowData.socialMedia.map((visitor) =>
      createData(
        visitor.socialPlatform,
        visitor.profileUrl,
        visitor.followersCount
      )
    );
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };

  const list = (
    <Box
      sx={{ width: showDetails ? 500 : 1000 }}
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>{showDetails ? "Details" : "Notes"}</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              {showDetails && (
                <>
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Marqfluencer Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.fullName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Mobile Number</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.mobileNumber}
                    </CustomProductSubheadingh>
                  </Grid>
                  {rowData?.email && (
                    <>
                      {" "}
                      <Divider
                        sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                      />
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                        <div>
                          <CustomInputLabel>Email Address</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.email}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}

                  {rowData?.contentCategory &&
                    rowData.contentCategory.length > 0 && (
                      <>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Category</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.contentCategory
                        .map((categoryObj) => categoryObj.influencerCategory)
                        .join(", ")}
                    </CustomProductSubheadingh>
                  </Grid>
                  </>)}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Collaboration Type</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.collaborationPreference}
                    </CustomProductSubheadingh>
                  </Grid>{" "}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Commercials</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.amount}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>Country Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.country?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>State Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.state?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                    <div>
                      <CustomInputLabel>City Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.city?.name}
                    </CustomProductSubheadingh>
                  </Grid>
                  {rowData?.otherCity && (
                    <>
                      {" "}
                      <Divider
                        sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                      />
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                        <div>
                          <CustomInputLabel>Other City</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.otherCity}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  {rowData?.otherCategory.length > 0 && (
                    <>
                      {" "}
                      <Divider
                        sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                      />
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
                        <div>
                          <CustomInputLabel>Other Category</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.otherCategory}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: "auto" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  backgroundColor: "#ffffff",
                                  fontFamily: '"Outfit", sans-serif',
                                  color: "#163e6e",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{
                                        fontFamily: '"Outfit", sans-serif',
                                        // color: "#000000",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "#000000",
                                      }}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </>
              )}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": { overflow: "auto", background: "#f4faff" },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
