import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaidIcon from "@mui/icons-material/Paid";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DatasetIcon from "@mui/icons-material/Dataset";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const SidebarData = [
  {
    title: "Dasboard",
    path: "/",
    icon: <GridViewIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,
  },
  {
    title: "Brand",

    icon: <LoyaltyIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,

    subNav: [
      {
        title: "Brands",
        path: "/brand",
        icon: <LoyaltyIcon />,
        cName: "sub-nav",
      },
      {
        title: "Products",
        path: "/product",
        icon: <LoyaltyIcon />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Marqfluencer",
    path: "/marqfluencer",
    icon: <Diversity3Icon />,
  },
  {
    title: "Campaign",
    icon: <CampaignIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,

    subNav: [
      {
        title: "Campaigns",
        path: "/campaign",
        icon: <CampaignIcon />,
      },
      {
        title: "Orders",
        path: "/order",
        icon: <CampaignIcon />,
      },
    ],
  },
  {
    title: "Transactions",
    icon: <PaidIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,

    subNav: [
      {
        title: "Payments",
        path: "/payment",
        icon: <PaidIcon />,
        cName: "sub-nav",
      },
      {
        title: "Reimbursements",
        path: "/reimbursement",
        icon: <PaidIcon />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Master Data",
    icon: <DatasetIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,

    subNav: [
      {
        title: "Status",
        path: "/status",
        icon: <DatasetIcon />,
        cName: "sub-nav",
      },
      {
        title: "Platform",
        path: "/platform",
        icon: <DatasetIcon />,
        cName: "sub-nav",
      },
      {
        title: "Campaign Type",
        path: "/campaigntype",
        icon: <DatasetIcon />,
        cName: "sub-nav",
      },
      {
        title: "Payment Mode",
        path: "/paymentMode",
        icon: <DatasetIcon />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Admins",
    icon: <SupervisorAccountIcon />,
    iconClosed: <ArrowDropDownIcon />,
    iconOpened: <ArrowDropUpIcon />,

    subNav: [
      {
        title: "Sub Admin",
        path: "/admin",
        icon: <SupervisorAccountIcon />,
        cName: "sub-nav",
      },
    ],
  },
];
