import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetBrand,
  useGetPlatform,
  useGetProduct,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import ProductView from "../../component/viewdetails/ProductView";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import RemovePopup from "../../component/removemasterdata/removePopup";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FilterPopupComponent from "../../common/filter/filterPopup";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import HistoryIcon from "@mui/icons-material/History";
import { setProductFilter } from "../../store/FilterSlice";
import { useDispatch, useSelector } from "react-redux";

const sortArray = [
  {
    sortOrder: "asc",
    sortField: "productName",
    sortName: "Product Name (Asc)",
  },
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
  {
    sortOrder: "asc",
    sortField: "productValue",
    sortName: "Product Value: Low to High",
  },
  {
    sortOrder: "desc",
    sortField: "productValue",
    sortName: "Product Value: High to Low",
  },
];

const ProductComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.productFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [editState, setEditState] = useState();
  const [rowDatanew, setrowDatanew] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [filterParam, setFilterParam] = useState("");
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "productname", label: "Product Name", minWidth: 300 },
    { id: "brandname", label: "Brand Name", minWidth: 200 },
    { id: "productvalue", label: "Product Value", minWidth: 200 },
    // { id: 'producturl', label: 'Product Url', minWidth: 100 },
    { id: "productplatform", label: "Product Platform", minWidth: 200 },
  ];
  const { allstatus, loading, totalPages, totalCount } = useGetProduct(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,triggerRefresh
  );
  const productNameFilter = useGetProduct();
  const brandFilter = useGetBrand();
  const productPlatformFilter = useGetPlatform();
  const realData = allstatus.map((item) => ({
    sno: item.sno,

    id: item._id,
    productname: item.productName,
    brandname: item.brand && item.brand?.brandName,
    productvalue: item.productValue,
    productplatform: item.productPlatform && item.productPlatform.platform,
  }));
  let filters = [
    {
      label: "Product Name",
      urlParam: "productname",
      values: productNameFilter?.allstatus.map((item) => ({
        label: item.productName.substring(0, 100),
        value: item._id,
      })),
    },

    {
      label: "Brand Name",
      urlParam: "brandname",
      values: brandFilter?.allstatus.map((item) => ({
        label: item.brandName,
        value: item._id,
      })),
    },

    {
      label: "Product Platform",
      urlParam: "productplatform",
      values: productPlatformFilter?.allstatus.map((item) => ({
        label: item.platform,
        value: item._id,
      })),
    },
  ];
  const actions = [
    {
      icon: NoteAddIcon,

      onClick: (row) => {

        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row);
        handleOpenModal();
      },
    },
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setHistoryDrawerOpen(true);
      },
    },
    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/product/edit", { state: { selectedData } });
      
      },
    },

    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
  ];
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  
  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
    dispatch(setProductFilter(value));
    setCurrentPage(1);
    setTriggerRefresh(prev => !prev);}
  };

  return (
    <>
      <ProductView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_PRODUCT"
        onSuccess={refreshData}

      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        // onFormSubmit={/* Pass any necessary submit function here */}
        editState={editState}
        showStatus={false}
        endpoint="ADMIN_PRODUCT_NOTE"
        onSuccess={refreshData}

      />
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Brands", href: "/brand" },
                { name: "Product", href: "/product" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Product</p>
              <span>{totalCount} &nbsp; Total Products</span>
            </div>
            <div>
              <CustomButton
                onClick={() => navigate("/product/add")}
                label="Add Product"
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="productFilter" 
            />
          </div>
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ProductComponent;
