import React, { useEffect, useState } from "react";
import { PATTERNS } from "../validetion";
import { Card, CardContent, FormControl, Grid } from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  postFormData,
  postJsonData,
  putFormData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useGetBrand, useGetPlatform } from "../customHooks/customHook";
import SubmitButton from "../coustombutton/SubmitButton";
import {  useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

const AddProduct = () => {
  
  const [formData, setFormData] = useState({
    productname: {
      value: "",
      isValid: true,
    },

    productvalue: {
      value:"",
      isValid: true,
    },

    producturl: {
      value:"",
      isValid: true,
    },
  });
  const [allBrand, setallBrand] = useState("");
  const [Platform, setproductPlatform] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const [imageFile, setImageFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const productPlatform = useGetPlatform();
  const brandDetails = useGetBrand();

  const navigate = useNavigate();

  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand(selectedOption.value);
    } else {
      setallBrand("");
    }
  };
  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      setproductPlatform(selectedOption.value);
    } else {
      setproductPlatform("");
    }
  };
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleProduct = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid =
      formData.productvalue.isValid &&
      formData.producturl.isValid &&
      allBrand &&
      Platform &&
      formData.productname.value.trim() !== "" &&
      formData.productvalue.value.trim() !== "" &&
      formData.producturl.value.trim() !== "";
    if (isFormValid) {
      const data = {
        productName: formData.productname.value,
        brand: allBrand,
        productValue: formData.productvalue.value,
        productUrl: formData.producturl.value,
        productPlatform: Platform,
      };
      if (imageFile) {
        data.productImage = imageFile;
      }
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });

      postFormData(
        VisitorEndpoint.ADD_PRODUCT,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/product");
            }
          }
          setIsDisabledNew(false);

        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);

        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);

    }
  };
  
  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Product", href: "/product" },
           
                 { name: "Add Product", href: "/product/add" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">
       Add Product
        </h1>
        <form onSubmit={handleProduct}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Product Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the name of the product.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="productname"
                      type="text"
                      placeholder="Enter Product Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.productname.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "productname", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the name of the brand, product is associated with.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={brandDetails?.allstatus.map((item) => ({
                        label: item.brandName,
                        value: item._id,
                      }))}
                      value={allBrand}
                      onChange={handleBrand}
                      name="flavor"
              
                    />

                    {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Product Value
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the price of the product.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="productvalue"
                      type="number" 
    onWheel={(e) => e.target.blur()}
                      placeholder="Enter Product Value"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.productvalue.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "productvalue", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Product Image</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Upload the image of the product.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="file"
                      type="file"
                      placeholder="Enter Product Image"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={handleFileChange}
                    />
              
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Product Platform
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the platform on which product is listed.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={productPlatform?.allstatus.map((item) => ({
                        label: item.platform,
                        value: item._id,
                      }))}
                      value={Platform}
                      onChange={handlePlatform}
                      name="flavor"
                    />
                    {formSubmitted && !Platform && (
                      <p className="input_text_error">
                        *Please select product platform.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Product URL
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the url of the product listing.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="producturl"
                      type="text"
                      placeholder="Enter Product URL"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.producturl.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "producturl", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.producturl.isValid || (
                      <p className="input_text_error">
                        *Please enter valid product URL.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <SubmitButton
                  type={isDisabledNew ? "button":"submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />{" "}          </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default AddProduct;
