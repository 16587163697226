import Icon from "../assets/images/camera1.png"
import PeopleIcon from '@mui/icons-material/People';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GridViewIcon from "@mui/icons-material/GridView";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaidIcon from "@mui/icons-material/Paid";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DatasetIcon from "@mui/icons-material/Dataset";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";


export const Admin = "Admin";
export const menuItem = [
    {
      path: "/",
      name: "Visitors Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
    {
      path: "/admin",
      name: "Sub Admin",
      icon: <ManageAccountsIcon sx={{fontSize:"30px"}} />,
    },


 
  ];
  
export const menuSubItem = [
    {
      path: "/",
      name: "Visitors Details",
      icon: <PeopleIcon sx={{fontSize:"30px"}} />,
    },
  ];
  
export  const visitordetails= [
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
  {
              "_id": "65cdc69531cb0d8cf21c20a5",
              "brandName": "Sereko",
              "brandPocName": "Shivani",
              "pocMobileNumber": 9898989898,
              "companyLegalName": "Sereko Pvt Limited",
              "companyLegalAddress": "D-2 Ashok Vihar, Delhi, 110009",
              "companyGstNumber": "22AAAAA0000A1Z5",
              "companyPanNumber": "QWER299Q",
              "deleted": false,
              "createdAt": "2024-02-15T08:08:53.099Z",
              "updatedAt": "2024-02-15T08:08:53.099Z",
              "__v": 0
          },
]
export const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },]
export const menuData = [
  {
    "name": "Dashboard",
    "icon": <GridViewIcon/>,
    "hoverMenu": false,
  },
  {
    "name": "Brands",
    "icon": <LoyaltyIcon/>,
    "hoverMenu": true,
    "subMenu": ["Brands", "Products"]

  },
  {
    "name": "Marqfluencer",
    "icon": <Diversity3Icon/>,
    "hoverMenu": false,
  },
  {
    "name": "Campaigns",
    "icon": <CampaignIcon/>,
    "hoverMenu": true,
    "subMenu": ["Campaigns", "Orders"]

  },
  {
    "name": "Transactions",
    "icon": <PaidIcon/>,
    "hoverMenu": true,
    "subMenu": ["Payments", "Reimbursements"]
  },
  {
    "name": "Master Data",
    "icon": <DatasetIcon/>,
    "hoverMenu": true,
    "subMenu": ["Status", "payment mode","Platform","Campaign Type"]
  },
  {
    "name": "Admins",
    "icon": <SupervisorAccountIcon/>,
    "hoverMenu": false
  }
]
