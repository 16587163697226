// EditModal.js
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, FormControl, Grid } from "@mui/material";
import {
  patchFormData,
  postJsonData,
  putJsonData,
} from "../../Services/AxoisInterceptorr";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { useGetBrand, useGetStatus } from "../customHooks/customHook";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import SingleSelect from "../../common/select/SingleSelect";
import { SmallButton } from "../coustombutton/SmallButton";
const options = [
  { label: "Brand", value: "Brand" },
  { label: "Marqfluencer", value: "Marqfluencer" },
  { label: "Campaign", value: "Campaign" },
  { label: "Order", value: "Order" },
  { label: "Collab", value: "Collab" },
];

<SingleSelect options={options} />;

const EditModal = ({
  open,
  onClose,
  row,
  onFormSubmit,
  editState,
  onStatusAdded,
}) => {
  useEffect(() => {
    if (editState) {
      setFormData({ status: editState?.status });
    } else {
      setFormData({ status: "" });
    }
    setallBrand(row?.statusgroup)
  }, [editState]);
  const [formData, setFormData] = useState({
    status: editState?.status ? editState.status : "",
  });
  const [allBrand, setallBrand] = useState("");


  
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = () => {
    const otpData = {
      status: formData.status,
      statusGroup: allBrand?._id,
    };
    postJsonData(
      VisitorEndpoint.ADD_STATUS,
      otpData,
      () => {},
      (res) => {
        if (res) {
          if (res && res.status === 201) {
            toast.success(res.data.message);
            // onStatusAdded();

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );

    // onFormSubmit(formData);
    onClose();
  };
  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand({
        _id: selectedOption.value,
        brandName: selectedOption.label,
      });
    } else {
      setallBrand("");
    }
  };
  const handleEdit = (e) => {
    e.preventDefault();
    // selectedCountryName &&
    // selectedStateName && brandCategory &&

    const editData = {
      status: formData.status || editState.status,
      statusGroup: allBrand || allBrand?._id,
    };

    const endpoint = `${VisitorEndpoint.EDIT_STATUS}/${editState?._id}`;
    putJsonData(
      endpoint,
      editData,
      () => {},
      (res) => {
        if (res && res.status === 200) {
          toast.success(res.data.message);
          setTimeout(() => {
            // navigate("/brand");
          }, 2000);
        }
      },
      (error) => {
        toast.error(error.response.data.message);
      }
    );
    onClose();
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          background: "#FFFFFF",
          padding: "50px",

          borderRadius: "20px",
        }}
      >
        <h2 className="edit_heading">Add Status</h2>
        {/* <TextField
          select
          label="Status"
          name="status"
          value={formData.status}
          onChange={handleFormChange}
          fullWidth
          sx={{ fontFamily: '"Quicksand", sans-serif' }}
        >
          {allstatus.map((stauts) => (
            <MenuItem
              key={stauts.status}
              value={stauts.status}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              {stauts.status}
            </MenuItem>
          ))}
        </TextField> */}
        <div className="edit_gap">
          <FormControl sx={{ width: "100%" }}>
            <CustomRequiredLabel>Status</CustomRequiredLabel>
            <DescriptionInput
              required
              label="Status"
              name="status"
              value={formData.status}
              onChange={handleFormChange}
              fullWidth
            />
          </FormControl>
          {!editState && (
          <Grid item xs={12} sm={4} sx={{ paddingTop: "15px" }}>
            <CustomRequiredLabel>Status Group</CustomRequiredLabel>
            <FormControl sx={{ width: "100%" }}>
              <SingleSelect
                options={options}
                value={allBrand}
                // defaultValue={preSelectedBrand ? { label: preSelectedBrand.brandName, value: preSelectedBrand._id } : null}
                onChange={handleBrand}
                name="flavor"
                defaultValue={
                          row?.statusgroup && row?.statusgroup
                            ? {
                                label: row?.statusgroup,
                                value: row?.statusgroup,
                              }
                            : ""
                        }
            
              />
            </FormControl>
          </Grid>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <SmallButton
            onClick={editState ? handleEdit : handleFormSubmit}
            label="Submit"
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
