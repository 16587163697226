import React, { useEffect, useState } from "react";
import { PATTERNS } from "../validetion";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  postFormData,
  postJsonData,
  putFormData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useGetBrand, useGetPlatform } from "../customHooks/customHook";
import SubmitButton from "../coustombutton/SubmitButton";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import { useUser } from "../../contexts/UserDataStore";
import {Admin} from "../../common/Constant"

const EditProduct = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const productData = state?.selectedData || {};
  const [formData, setFormData] = useState({
    productname: {
      value: productData?.productName || "",
      isValid: true,
    },

    productvalue: {
      value: productData?.productValue || "",
      isValid: true,
    },

    producturl: {
      value: productData?.productUrl || "",
      isValid: true,
    },
  });
  const [allBrand, setallBrand] = useState("");
  const [Platform, setproductPlatform] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const [fileName, setFileName] = useState("");
  const productPlatform = useGetPlatform();
  const brandDetails = useGetBrand();
 
  const navigate = useNavigate();

  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand({
        _id: selectedOption.value,
        brandName: selectedOption.label,
      });
    } else {
      setallBrand("");
    }
  };
  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      setproductPlatform({
        _id: selectedOption.value,
        platform: selectedOption.label,
      });
    } else {
      setproductPlatform("");
    }
  };

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  useEffect(() => {
    if (productData?.productImage?.image) {
      // setImageFile(productData?.productImage?.image);
      setImageFile(productData?.productImage?.imageUrl);
      setFileName(productData?.productImage?.image);
      // setFileName(productData?.productImage?.imageUrl);
    }
  }, [productData]);

  const handleEdit = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid =
      (formData.productvalue.isValid || productData.productvalue) &&
      (formData.producturl.isValid || productData.productUrl) &&
      allBrand &&
      Platform;
    // formData.productvalue.value.trim() !== "" &&
    // formData.producturl.value.trim() !== "";
    if (isFormValid) {
      const editData = {
        productName: formData.productname.value || productData.productname,
        brand: allBrand?.brand?._id || allBrand?._id,
        productValue: formData.productvalue.value || productData.productvalue,
        productUrl: formData.producturl.value || productData.productUrl,
        productPlatform: Platform?.productPlatform?._id || Platform?._id,
      };

      if (imageFile || fileName) {
        editData.productImage = imageFile || fileName;
      }
      const newformData = new FormData();
      Object.keys(editData).forEach((key) => {
        newformData.append(key, editData[key]);
      });

      const endpoint = `${VisitorEndpoint.EDIT_PRODUCT}/${productData?._id}`;
      putFormData(
        endpoint,
        newformData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/product");
            }, 2000);
          }
          setIsDisabledNew(false);

        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);

        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);

    }


  };
  useEffect(() => {
    setLoading(true);
    setallBrand(productData);
    setproductPlatform(productData);
    setLoading(false);
  }, [productData]);

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <ActiveLastBreadcrumb
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Product", href: "/product" },
                  { name: "Edit Product", href: "/product/edit" },
                ]}
              />
            </div>
            <h1 className="have_solution_heading">Edit Product</h1>
            <form onSubmit={handleEdit}>
              <Card sx={{ padding: "20px" }}>
                <CardContent>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Product Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the name of the product.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="productname"
                          type="text"
                          placeholder="Enter Product Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          disabled={user?.role !== Admin && productData?.productName}
                          value={formData.productname.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "productname", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Brand Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the name of the brand, product is associated
                        with.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={brandDetails?.allstatus.map((item) => ({
                            label: item.brandName,
                            value: item._id,
                          }))}
                          value={allBrand}
                          onChange={handleBrand}
                          name="flavor"
                          isDisabled={ user?.role !== Admin &&
                            productData && productData.brand
                              ? !!productData.brand.brandName
                              : false
                          }
                          defaultValue={
                            allBrand && allBrand?.brand
                              ? {
                                  label: allBrand?.brand?.brandName,
                                  value: allBrand?.brand?._id,
                                }
                              : ""
                          }
                        />

                        {formSubmitted && !allBrand && (
                          <p className="input_text_error">
                            *Please select brand name.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Product Value
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the price of the product.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="productvalue"
                          type="number" 
    onWheel={(e) => e.target.blur()}
                          placeholder="Enter Product Value"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.productvalue.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "productvalue", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Product Image</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Upload the image of the product.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="file"
                          type="file"
                          placeholder="Enter Product Image"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={handleFileChange}
                        />
                        {fileName && Object.keys(productData).length > 0 && (
                          <p>Selected file: {fileName}</p>
                        )}
                      
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Product Platform
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the platform on which product is listed.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={productPlatform?.allstatus.map((item) => ({
                            label: item.platform,
                            value: item._id,
                          }))}
                          value={Platform}
                          onChange={handlePlatform}
                          name="flavor"
                          defaultValue={
                            Platform && Platform?.productPlatform
                              ? {
                                  label: Platform?.productPlatform?.platform,
                                  value: Platform?.productPlatform?._id,
                                }
                              : ""
                          }
                        />
                        {formSubmitted && !Platform && (
                          <p className="input_text_error">
                            *Please select product platform.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Product URL
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the url of the product listing.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="producturl"
                          type="text"
                          placeholder="Enter Product URL"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.producturl.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "producturl", PATTERNS.WEBSITE_URL);
                          }}
                        />
                        {formData.producturl.isValid || (
                          <p className="input_text_error">
                            *Please enter valid product URL.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <SubmitButton
                  type={isDisabledNew ? "button":"submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />{" "}               </CardContent>
              </Card>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default EditProduct;
