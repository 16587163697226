import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { NoDataImage } from "../IconsImport";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import { utcToIst } from "../../common/utctimechange";

export default function ProductView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
    { id: "updatedby", label: "Updated By", minWidth: 200 },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };
  const realData = rowData?.adminNoteHistory.map((item,index) => ({
    sno:index+1,

    adminnote: item.adminNote && item.adminNote,
    updatedat:
    item.updatedAt && utcToIst(item.updatedAt),    updatedby: item.updatedBy?.name,
  }));
  const list = (
    <Box
     
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
          <h1>{showDetails ? "Details" : "Notes"}</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            {showDetails && (
              <Tooltip placement="top" title="Brand History">
                <HistoryIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(true);
                    setShowDetails(false);
                  }}
                />
              </Tooltip>
            )}
            {showHistory && (
              <Tooltip placement="top" title="Product Details">
                <VisibilityIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(false);
                    setShowDetails(true);
                  }}
                />
              </Tooltip>
            )}
          </div>
          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              {showHistory && (
                <>
                  <ShortingTable
                    columns={columns}
                    // loading={activeCampaignDetailsLoading}
                    data={realData}
                  />

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
              {showDetails && (
                <>
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Brand Name</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {rowData?.brand && rowData?.brand.brandName}

                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

              <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Product Name</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                  {rowData?.productName}
                </CustomProductSubheadingh>
              </Grid>
              {rowData?.productImage && (<>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <CustomInputLabel>Product Image</CustomInputLabel>

                <Avatar
                  sx={{ width: "120px", height: "120px" }}
                  src={rowData?.productImage.imageUrl}
                  alt={rowData?.productImage.image}
                />
              

                {/* <Avatar
                  sx={{ width: "150px", height: "150px" }}
                  src={NoDataImage}
                  alt="logo"
                /> */}
              </Grid>
              </>)}
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Product Value</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
                  {rowData?.productValue}
                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{  borderBottom: "1px solid #ccc" }} />
              <Grid item xs={12} sm={12} sx={{ marginTop: "10px" }}>
                <div>
                  <CustomInputLabel>Product Url</CustomInputLabel>
                </div>
                <Link  underline="hover" target="_blank" href={rowData?.productUrl}>
                <CustomProductSubheadingh>
                {rowData?.productUrl.split('/').slice(0, 5).join('/')}
                </CustomProductSubheadingh>
                </Link>
              </Grid>
            </Grid>
            </>
              )}
              </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {overflow: "auto",background:"#f4faff",width:"90%", },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
