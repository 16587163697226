import React, { useEffect, useState } from "react";
import {
  CustomRequiredLabel,
  CustomInputLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import { Card, CardContent, FormControl, Grid } from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  useBrandCategory,
  useCities,
  useCountries,
  useStates,
} from "../customHooks/customHook";
import {
  postJsonData,
  putFormData,
  putJsonData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import CustomButton from "../coustombutton/CoustomButton";
import SubmitButton from "../coustombutton/SubmitButton";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

const AddBrand = () => {
  const [formData, setFormData] = useState({
    brandname: {
      value: "",
      isValid: true,
    },
    brandpocname: {
      value: "",
      isValid: true,
    },
    phone: {
      value: "",
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
    },
    companyname: {
      value: "",
      isValid: true,
    },
    companyaddress: {
      value: "",
      isValid: true,
    },
    companygst: {
      value: "",
      isValid: true,
    },
    companypan: {
      value: "",
      isValid: true,
    },
    brandwebsite: {
      value: "",
      isValid: true,
    },
  });
  const [brandCategory, setbrandCategory] = useState("");
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [otherCity, setOtherCity] = useState("");
  const [isOtherCity, setIsOtherCity] = useState(false);
  const [isBrandAdded, setIsBrandAdded] = useState(false);

  const countries = useCountries();
  const brandCategories = useBrandCategory();
  const states = useStates(selectedCountryIso2?._id);
  const cities = useCities(age?._id);

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const handleBrandCategory = (selectedOption) => {
    if (selectedOption) {
      setbrandCategory({
        _id: selectedOption.value,
        brandCategory: selectedOption.label,
      });
    } else {
      setbrandCategory("");
    }
  };

  const handlesetCountryChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCountryIso2({
        _id: selectedOption.value,
        country: selectedOption.label,
      });
    } else {
      setSelectedCountryIso2("");
    }
  };
  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      setAge({
        _id: selectedOption.value,
        state: selectedOption.label,
      });
    } else {
      setAge("");
    }
  };
  const handleCityChange = (event) => {
    if (event) {
      setCity(event.value);
    } else {
      setCity("");
    }
  };

  const selectedCountryName = countries?.find(
    (country) => country.name === selectedCountryIso2.country
  )?._id;

  const selectedStateName = states?.find(
    (state) => state.name === age.state
  )?._id;



  const handleBrand = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setIsBrandAdded(true);

    setFormSubmitted(true);
    const isFormValid =
      formData.phone.isValid &&
      formData.email.isValid &&
      selectedCountryName &&
      selectedStateName &&
      brandCategory &&
      // formData.companyname.isValid &&
      // formData.companyaddress.isValid &&
      // formData.companygst.isValid &&
      // formData.companypan.isValid &&
      formData.brandwebsite.isValid &&
      formData.phone.value.trim() !== "";

    if (isFormValid) {
      const data = {
        brandName: formData.brandname.value,
        brandPocName: formData.brandpocname.value,
        pocMobileNumber: formData.phone.value,
        pocEmail: formData.email.value,
        companyLegalName: formData.companyname.value,
        companyLegalAddress: formData.companyaddress.value,
        companyGstNumber: formData.companygst.value,
        companyPanNumber: formData.companypan.value,
        brandWebsite: formData.brandwebsite.value,
        brandCategory: brandCategory?._id,
        country: selectedCountryName,
        state: selectedStateName,
        city: city,
      };
      postJsonData(
        VisitorEndpoint.ADD_BRAND,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/brand");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Brands", href: "/brand" },
              { name: "Add Brand", href: "/brand/add" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Add Brand</h1>

        <form onSubmit={handleBrand}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the name of the brand.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="brandname"
                      type="text"
                      placeholder="Enter Brand Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.brandname.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "brandname", null);
                      }}
                    />
                    {/* {formData.brandname.isValid || (
                      <p className="input_text_error">
                        *Please enter the name of the brand.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Brand Poc Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the full name of the brand's primary point of contact.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="brandpocname"
                      type="text"
                      placeholder="Enter Brand Poc Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.brandpocname.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "brandpocname", PATTERNS.PERSONNEW_NAME);
                      }}
                    />
                    {formData.brandpocname.isValid || (
                      <p className="input_text_error">
                        *Please enter the full name of the brand's.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Brand Poc Number
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the mobile number of the brand's primary point of
                    contact.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="phone"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Brand Poc Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.phone.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "phone", PATTERNS.MOBILE);
                      }}
                    />
                    {formData.phone.isValid || (
                      <p className="input_text_error">
                        *Please enter the mobile number of the brand's.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Brand Poc Email</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the email address of the brand's primary point of
                    contact.{" "}
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="email"
                      type="text"
                      placeholder="Enter Brand Poc Email"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.email.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "email", PATTERNS.EMAIL);
                      }}
                    />
                    {formData.email.isValid || (
                      <p className="input_text_error">
                        *Please enter the email address of the brand's.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Brand Website</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the website url of the brand's.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="brandwebsite"
                      type="text"
                      placeholder="Enter Brand Website URL"
                      id="outlined-required"
                      value={formData.brandwebsite.value}
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "brandwebsite", PATTERNS.WEBSITE_URL);
                      }}
                    />
                    {formData.brandwebsite.isValid || (
                      <p className="input_text_error">
                        *Please enter the website url of the brand's.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Brand Category
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the category in which brand is associated in.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={brandCategories?.allstatus.map((item) => ({
                        label: item.brandCategory,
                        value: item._id,
                      }))}
                      value={brandCategory}
                      onChange={handleBrandCategory}
                      name="flavor"
                    />
                    {formSubmitted && !brandCategory && (
                      <p className="input_text_error">
                        *Please select at least one category.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Company Legal Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the full legal name as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="companyname"
                      type="text"
                      placeholder="Enter Company Legal Name"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.companyname.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "companyname", null);
                      }}
                    />
                    {/* {formData.companyname.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                    Company Legal Address
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the full legal address as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="companyaddress"
                      type="text"
                      value={formData.companyaddress.value}
                      placeholder="Enter Company Legal Address"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "companyaddress", null);
                      }}
                    />
                    {/* {formData.companyaddress.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Company Gst Number</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the GST number as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="companygst"
                      type="text"
                      placeholder="Enter Company Gst Number"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.companygst.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "companygst", null);
                      }}
                    />
                    {/* {formData.companygst.isValid || (
                      <p className="input_text_error">
                        *Please enter valid GST number as it appears on your
                        official identification.
                      </p>
                    )} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Company Pan Number</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the PAN number as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="companypan"
                      type="text"
                      placeholder="Enter Company Pan Number"
                      id="outlined-required"
                      label="First Name"
                      value={formData.companypan.value}
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "companypan", null);
                      }}
                    />
                    {formData.companypan.isValid || (
                      <p className="input_text_error">
                        *Please enter valid PAN number as it appears on your
                        official identification.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Country Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the country name as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={countries.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))}
                      value={selectedCountryIso2}
                      onChange={handlesetCountryChange}
                      name="flavor"
                    />
                    {formSubmitted && !selectedCountryIso2 && (
                      <p className="input_text_error">
                        *Please select a country.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>State Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the state name as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={states.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))}
                      value={age}
                      onChange={handleStateChange}
                      name="flavor"
                      menuPlacement="top"
                      defaultValue={
                        age && age.state
                          ? {
                              label: age.state.name,
                              value: age._id,
                            }
                          : null
                      }
                    />
                    {formSubmitted && !age && (
                      <p className="input_text_error">
                        *Please select a state/UT.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>City Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the city name as it appears on your official
                    identification.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={[
                        { label: "Other", value: "65f28af9115b7ec97647b52d" },
                        ...cities.map((item) => ({
                          label: item.name,
                          value: item._id,
                        })),
                      ]}
                      menuPlacement="top"
                      value={city}
                      onChange={handleCityChange}
                      name="flavor"
                    />

                    {formSubmitted && !city && (
                      <p className="input_text_error">*Please select a city.</p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <SubmitButton
                type={isDisabledNew ? "button" : "submit"}
                label={isDisabledNew ? "Loading..." : "Submit"}
                disabled={isDisabledNew}
              />{" "}
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default AddBrand;
