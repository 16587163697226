import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SubMenu from "./SubMenu";
import "./Sidebar.css"; // Import the CSS file
import { SidebarDataOperations } from "./SidebarDataOperations";
import { useUser } from "../../contexts/UserDataStore";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const { user } = useUser();
  const showSidebar = () => setSidebar(!sidebar);
  const filteredSidebarData = user?.role === 'Admin' ? SidebarData : SidebarDataOperations;

  return (
    <>
      <div className="navbar">
        <div>
          <p className="logo_header">marqeting</p>
        </div>
        <Link to="#" className="nav-icon">
          <MenuIcon
            onClick={showSidebar}
            sx={{ color: "#ffffff", fontSize: 42 }}
          />
        </Link>
      </div>
      <div className={sidebar ? "sidebar-nav sidebar-nav-open" : "sidebar-nav"}>
        <div className="sidebar-wrap">
          <Link to="#" className="nav-icon" onClick={showSidebar}>
            <CloseIcon
              onClick={showSidebar}
              sx={{ color: "#163e6e", fontSize: 42 }}
            />
          </Link>
          {filteredSidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
         


        </div>
      </div>
    </>
  );
};

export default Sidebar;
