import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetOrders,
  useGetPayments,
  useGetStatus,
  useBrandCategory,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import CustomButton from "../../component/coustombutton/CoustomButton";
import EditModal from "../../component/masterpopup/brandCategoryModal";
import RemovePopup from "../../component/removemasterdata/removePopup";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";

const BrandCategoryComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [editState, setEditState] = useState();
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "brandCategory", label: "Brand Category", minWidth: 200 },
  ];
  const { allstatus, loading, totalPages, totalCount } = useBrandCategory(
    currentPage,
    perPageLimit,
    triggerRefresh
  );
  const realData = allstatus.map((item) => ({
    sno: item.sno,

    id: item._id,
    brandCategory: item.brandCategory,
  }));
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const actions = [
    {
      icon: EditIcon,
      onClick: (row) => {
        handleOpen();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
  ];
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleOpen = () => {
    setEditState(null);
    setOpenModal(true);
  };

  const handleClose = () => {
    setEditState(null);
    setOpenModal(false);
  };
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
        <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Brand Category", href: "/brandcategory" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Brand Category</p>
              <span>{totalCount}&nbsp;Brand Category</span>
            </div>
            <div>
              <CustomButton label="Add Brand Category" onClick={handleOpen} />
            </div>
          </div>
       
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
           <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            onRowsPerPageChange={(event) =>
              setPerPageLimit(parseInt(event.target.value, 25))
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
            }}
          />
        </div>
      </div>
      <EditModal open={openModal} onClose={handleClose} editState={editState} />
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_BRANDCATEGORY"
        onSuccess={refreshData}

      />
    </>
  );
};
export default BrandCategoryComponent;
