import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const SmallButton = ({
  onClick,
  label,
  color = "#ffffff",
  background = "#163e6e",
  type,
  width,
  marginRight,
  padding = "5px 20px",
  fontSize = "16px",
  endIcon,
  disabled
  
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        size="small"
        sx={{
          width: { width },
          borderRadius: "20px",
          padding: padding,
          color: color,
          fontFamily: '"Outfit", sans-serif',
          background: background,
          fontSize: fontSize,
          fontWeight: 500,
          marginRight: marginRight,
          "&:hover": {
            background: background,
            color: color,
          },
          textTransform: "none",
        }}
        type={type}
        onClick={onClick}
        endIcon={endIcon} 
        disabled={disabled}
      >
        {label}
      </Button>
    </Box>
  );
};
