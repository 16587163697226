import React, { useEffect, useState } from "react";
import {
  CustomRequiredLabel,
  CustomInputLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  useBrandCategory,
  useCities,
  useCountries,
  useStates,
} from "../customHooks/customHook";
import {
  postJsonData,
  putFormData,
  putJsonData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import CustomButton from "../coustombutton/CoustomButton";
import SubmitButton from "../coustombutton/SubmitButton";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import {Admin} from "../../common/Constant"
import { useUser } from "../../contexts/UserDataStore";

const EditBrand = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const brandData = state?.selectedData || {};
  console.log(brandData);
  const [formData, setFormData] = useState({
    brandname: {
      value: brandData?.brandName || "",
      isValid: true,
    },
    brandpocname: {
      value: brandData?.brandPocName || "",
      isValid: true,
    },
    phone: {
      value: brandData?.pocMobileNumber || "",
      isValid: true,
    },
    email: {
      value: brandData?.pocEmail || "",
      isValid: true,
    },
    companyname: {
      value: brandData?.companyLegalName || "",
      isValid: true,
    },
    companyaddress: {
      value: brandData?.companyLegalAddress || "",
      isValid: true,
    },
    companygst: {
      value: brandData?.companyGstNumber || "",
      isValid: true,
    },
    companypan: {
      value: brandData?.companyPanNumber || "",
      isValid: true,
    },
    brandwebsite: {
      value: brandData?.brandWebsite || "",
      isValid: true,
    },
  });
  const [brandCategory, setbrandCategory] = useState("");
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [otherCity, setOtherCity] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const countries = useCountries();
  const brandCategories = useBrandCategory();
  const states = useStates(selectedCountryIso2?.country?.name);
  const cities = useCities(age?.state?.name);

  // console.log(brandCategories);
  // console.log(countries);
  // console.log(cities);
  // console.log(age);
  // console.log(city);
  // const setValues = (e, field, regEx) => {
  //   const value = e.target.value;

  //   const isValid = value.trim() === "" || validateInput(value, regEx);
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [field]: {
  //       value: value,
  //       isValid: isValid,
  //     },
  //   }));
  // };

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const handleBrandCategory = (selectedOption) => {
    if (selectedOption) {
      setbrandCategory({
        _id: selectedOption.value,
        brandCategory: selectedOption.label,
      });
    } else {
      setbrandCategory("");
    }
  };

  // const handleBrandCategory = (selectedOptions) => {
  //   if (selectedOptions) {

  //   setbrandCategory(selectedOptions.value);
  // } else {
  //   setbrandCategory("");
  // }
  // };
  const handlesetCountryChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCountryIso2({
        _id: selectedOption.value,
        country: selectedOption.label,
      });
    } else {
      setSelectedCountryIso2("");
    }
  };
  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      setAge({
        _id: selectedOption.value,
        state: selectedOption.label,
      });
    } else {
      setAge("");
    }
  };
  // useEffect(() => {
  //   console.log("loki", brandCategory);
  // }, [brandCategory]);
  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setCity({
        _id: selectedOption.value,
        city: selectedOption.label,
      });
    } else {
      setCity("");
    }
  };

  const selectedCountryName = countries?.find(
    (country) => country.name === selectedCountryIso2.country
  )?._id;

  const selectedStateName = states?.find(
    (state) => state.name === age.state
  )?._id;



  const handleOtherCityChange = (event) => {
    setOtherCity(event.target.value);
  };

  // console.log(brandCategory?.brandCategory.brandCategory._id,"lokkh")

  const handleEditBrand = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid = formData.brandpocname.isValid || brandData.brandPocName;
    // (formData.phone.isValid || brandData.pocMobileNumber) &&
    // (formData.email.isValid || brandData.pocEmail) &&
    // (formData.companygst.isValid || brandData.companyGstNumber) &&
    // (formData.companypan.isValid || brandData.companyPanNumber) &&
    // (formData.brandwebsite.isValid || brandData.brandWebsite);
    // selectedCountryName &&
    // selectedStateName && brandCategory &&
    if (isFormValid) {
      const editData = {
        brandName: formData.brandname.value || brandData.brandName,
        brandPocName: formData.brandpocname.value || brandData.brandPocName,
        pocMobileNumber: formData.phone.value || brandData.pocMobileNumber,
        pocEmail: formData.email.value || brandData.pocEmail,
        companyLegalName:
          formData.companyname.value || brandData.companyLegalName,
        companyLegalAddress:
          formData.companyaddress.value || brandData.companyLegalAddress,
        companyGstNumber:
          formData.companygst.value || brandData.companyGstNumber,
        companyPanNumber:
          formData.companypan.value || brandData.companyPanNumber,
        brandWebsite: formData.brandwebsite.value || brandData.brandWebsite,
        status: brandData?.status?._id,
        brandCategory: brandCategory?.brandCategory._id || brandCategory?._id,
        country: selectedCountryName || brandData?.country?._id,
        state: selectedStateName || brandData?.state?._id,
        city: city?.city?._id || city?._id,
      };

      const endpoint = `${VisitorEndpoint.EDIT_BRAND}/${brandData?._id}`;
      putJsonData(
        endpoint,
        editData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            navigate("/brand");
          }
          setIsDisabledNew(false);

        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);

        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);

    }
   
  };

  useEffect(() => {
    setLoading(true);
    setbrandCategory(brandData);
    setSelectedCountryIso2(brandData);
    setAge(brandData);
    if (brandData.city) {
      setCity(brandData);
    } else {
      setCity(null);
    }
    setLoading(false);
  }, [brandData]);

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <ActiveLastBreadcrumb
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Brands", href: "/brand" },
                  { name: "Edit Brand", href: "/brand/edit" },
                ]}
              />
            </div>
            <h1 className="have_solution_heading">Edit Brand</h1>

            <form onSubmit={handleEditBrand}>
              <Card sx={{ padding: "20px" }}>
                <CardContent>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Brand Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the name of the brand.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="brandname"
                          type="text"
                          placeholder="Enter Brand Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          disabled={ user?.role !== Admin && brandData?.brandName}
                          value={formData.brandname.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "brandname", null);
                          }}
                        />
                        {/* {formData.brandname.isValid || (
                      <p className="input_text_error">
                        *Please enter the name of the brand.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Brand Poc Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the full name of the brand's primary point of
                        contact.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="brandpocname"
                          type="text"
                          placeholder="Enter Brand Poc Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.brandpocname.value}
                          size="small"
                          onChange={(e) => {
                            setValues(
                              e,
                              "brandpocname",
                              null
                            );
                          }}
                        />
                        {formData.brandpocname.isValid || (
                          <p className="input_text_error">
                            *Please enter the full name of the brand's.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Brand Poc Number
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the mobile number of the brand's primary point of
                        contact.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="phone"
                          type="number" 
    onWheel={(e) => e.target.blur()}
                          placeholder="Enter Brand Poc Number"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.phone.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "phone", PATTERNS.MOBILE);
                          }}
                        />
                        {formData.phone.isValid || (
                          <p className="input_text_error">
                            *Please enter the mobile number of the brand's.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Brand Poc Email</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the email address of the brand's primary point of
                        contact.{" "}
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="email"
                          type="text"
                          placeholder="Enter Brand Poc Email"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.email.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "email", PATTERNS.EMAIL);
                          }}
                        />
                        {formData.email.isValid || (
                          <p className="input_text_error">
                            *Please enter the email address of the brand's.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Brand Website</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the website url of the brand's.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="brandwebsite"
                          type="text"
                          placeholder="Enter Brand Website URL"
                          id="outlined-required"
                          value={formData.brandwebsite.value}
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "brandwebsite", PATTERNS.WEBSITE_URL);
                          }}
                        />
                        {formData.brandwebsite.isValid || (
                          <p className="input_text_error">
                            *Please enter the website url of the brand's.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Brand Category
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the category in which brand is associated in.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={brandCategories?.allstatus.map((item) => ({
                            label: item?.brandCategory,
                            value: item?._id,
                          }))}
                          value={brandCategory}
                          onChange={handleBrandCategory}
                          name="flavor"
                          // defaultValue={
                          //     brandCategory && {
                          //       label: brandCategory?.brandCategory.brandCategory,
                          //       value: brandCategory?._id,
                          //     }}
                          defaultValue={
                            brandCategory && brandCategory?.brandCategory
                              ? {
                                  label:
                                    brandCategory?.brandCategory?.brandCategory,
                                  value: brandCategory?._id,
                                }
                              : ""
                          }
                        />
                        {formSubmitted && !brandCategory && (
                          <p className="input_text_error">
                            *Please select at least one category.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Company Legal Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the full legal name as it appears on your official
                        identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="companyname"
                          type="text"
                          placeholder="Enter Company Legal Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.companyname.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "companyname", null);
                          }}
                        />
                        {/* {formData.companyname.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Company Legal Address
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the full legal address as it appears on your
                        official identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="companyaddress"
                          type="text"
                          value={formData.companyaddress.value}
                          placeholder="Enter Company Legal Address"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "companyaddress", null);
                          }}
                        />
                        {/* {formData.companyaddress.isValid || (
                      <p className="input_text_error">
                        *Please enter your full name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Company Gst Number
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the GST number as it appears on your official
                        identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="companygst"
                          type="text"
                          placeholder="Enter Company Gst Number"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.companygst.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "companygst", null);
                          }}
                        />
                        {formData.companygst.isValid || (
                          <p className="input_text_error">
                            *Please enter valid GST number as it appears on your
                            official identification.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Company Pan Number
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the PAN number as it appears on your official
                        identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="companypan"
                          type="text"
                          placeholder="Enter Company Pan Number"
                          id="outlined-required"
                          label="First Name"
                          value={formData.companypan.value}
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "companypan", null);
                          }}
                        />
                        {formData.companypan.isValid || (
                          <p className="input_text_error">
                            *Please enter valid PAN number as it appears on your
                            official identification.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Country Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the country name as it appears on your official
                        identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={countries.map((item) => ({
                            label: item.name,
                            value: item.name,
                          }))}
                          value={selectedCountryIso2}
                          onChange={handlesetCountryChange}
                          // onChange={handleChange}
                          name="flavor"
                          // defaultValue={
                          //   selectedCountryIso2 && {
                          //       label: selectedCountryIso2?.country.name,
                          //       value: selectedCountryIso2?._id,
                          //     }}
                          defaultValue={
                            selectedCountryIso2 && selectedCountryIso2.country
                              ? {
                                  label: selectedCountryIso2?.country?.name,
                                  value: selectedCountryIso2?._id,
                                }
                              : null
                          }
                        />
                        {formSubmitted && !selectedCountryIso2 && (
                          <p className="input_text_error">
                            *Please select a country.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        State Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the state name as it appears on your official
                        identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={states.map((item) => ({
                            label: item.name,
                            value: item.name,
                          }))}
                          value={age}
                          onChange={handleStateChange}
                          name="flavor"
                          defaultValue={
                            age && age.state
                              ? {
                                  label: age?.state?.name,
                                  value: age?._id,
                                }
                              : null
                          }
                        />
                        {formSubmitted && !age && (
                          <p className="input_text_error">
                            *Please select a state/UT.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        City Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the city name as it appears on your official
                        identification.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={[
                            {
                              label: "Other",
                              value: "65f28af9115b7ec97647b52d",
                            },
                            ...cities.map((item) => ({
                              label: item?.name,
                              value: item?._id,
                            })),
                          ]}
                          value={city}
                          onChange={handleCityChange}
                          name="flavor"
                          defaultValue={
                            city && city
                              ? {
                                  label: city?.city?.name,
                                  value: city?.city?._id,
                                }
                              : null
                          }
                        />

                        {formSubmitted && !city && (
                          <p className="input_text_error">
                            *Please select a city.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <SubmitButton
                  type={isDisabledNew ? "button":"submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />{" "}
                </CardContent>
              </Card>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default EditBrand;
