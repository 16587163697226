import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../tabel/DataTabel";
import ShortingTable from "../tabel/ShortingTabel";
import {
  useGetAllActiveCampaignByM,
  useGetInfluncersSatgeDetails,
  useGetTotalNumberpaReimbursementPendingM,
  useGetTotalNumberpaReimbursementrCamapignM,
  useGetTotalNumberparActiveCamapignM,
  useGetTotalNumberparticipatedCamapign,
} from "../customHooks/customHook";
import Pagination from "../tabel/pagination";
import CustomButton from "../coustombutton/CoustomButton";
import SortPopup from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import Stack from "@mui/material/Stack";
import CardComponent from "../../common/card/CardComponent";
import PaidIcon from "@mui/icons-material/Paid";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import BrandActiveCampaignComponent from "../activeCampaign/BrandActiveCampaign";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Reimbursement } from "../IconsImport";

const MarqfluencerStatsView = () => {
  const { id } = useParams();
  const { allstatus: totalParticipatedCampaign, loading: totalCampaignLoading } =
  useGetTotalNumberparticipatedCamapign(id);
  const {
    allstatus: totalActiveCampaign,
    loading: totalActiveCampaignLoading,
  } = useGetTotalNumberparActiveCamapignM(id);
  const { allstatus: totalReimbursement, loading: totalPaymentLoading } =
  useGetTotalNumberpaReimbursementrCamapignM(id);
  const {
    allstatus: totalPendingReimbursement,
    loading: totalPendingPaymentLoading,
  } = useGetTotalNumberpaReimbursementPendingM(id);
    const { allstatus: influncerDetails, loading: brandDetailsLoading } =
    useGetInfluncersSatgeDetails(id);
   
  const {
    allstatus: activeCampaignDetails,
    loading: activeCampaignDetailsLoading,
  } = useGetAllActiveCampaignByM(id);
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "campaignname", label: "Campaign Name", minWidth: 200 },
    { id: "brandname", label: "Brand Name", minWidth: 200 },
    { id: "campaigntype", label: " Campaign Type", minWidth: 200 },
    { id: "status", label: " Status", minWidth: 200 },
  ];
  const realData = activeCampaignDetails?.map((detail) => 
  detail.activeCampaigns.map((item,index) => ({
    sno: index+1,

    campaignname: item.campaignName && item.campaignName,
    brandname: item.brand && item.brand[0]?.brandName,
    campaigntype: item.campaignType && item.campaignType[0]?.campaignType,
    status: item.status && item.status[0]?.status,
  }))
).flat();




  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Marqfluencer", href: "/marqfluencer" },
                { name: "Stats", href: `/marqfluencer/stats/${id}` },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              {brandDetailsLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "30px" }}
                  width={150}
                />
              ) : (
                <p>{influncerDetails?.influencerName}</p>
              )}
            </div>
          </div>
          {influncerDetails?.status && (
          <div className="status_content">
            {brandDetailsLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={100} />
            ) : (
              <p>{influncerDetails?.status ? influncerDetails?.status?.status : ""}</p>
            )}
          </div>)}
          <div className="card_container">
            <CardComponent
              logoColor="purple"
              icon={CampaignIcon}
              data={totalParticipatedCampaign[0]?.total_campaign}
              heading="Participated Campaigns"
              loading={totalCampaignLoading}
            />
            <CardComponent
              logoColor="blue"
              icon={CampaignIcon}
              data={totalActiveCampaign[0]?.total_active_campaign}
              heading="Active Campaigns"
              loading={totalActiveCampaignLoading}
            />
            <CardComponent
              logoColor="green"
              image={Reimbursement}
              heading="Reimbursement Made"
              data={totalReimbursement[0]?.total_reimbursement}
              loading={totalPaymentLoading}
            />
            <CardComponent
              logoColor="pink"
              image={Reimbursement}
              heading="Reimbursement Pending"
              data={totalPendingReimbursement.total_pending}
              color={
                totalPendingReimbursement.total_pending > 0 ? "red" : "red"
              }
              loading={totalPendingPaymentLoading}
              
            />
            
          </div>

          <div className="brand_details_main_container">
            {/* <div className="brand_details_container"> */}
              <h1 className="country_text">All Active Campaign</h1>
              <ShortingTable
                columns={columns}
                loading={activeCampaignDetailsLoading}
                data={realData}
              />
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default MarqfluencerStatsView;
