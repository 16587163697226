import React, { useEffect, useState } from "react";
import { PATTERNS } from "../validetion";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  useCities,
  useCollbType,
  useCountries,
  useInfluncersCategory,
  useStates,
} from "../customHooks/customHook";
import { toast } from "react-toastify";
import { postJsonData, putJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import CustomButton from "../coustombutton/CoustomButton";
import SubmitButton from "../coustombutton/SubmitButton";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

const EditMarqfluencer = () => {
  const { state } = useLocation();
  const marqfluencerData = state?.selectedData || {};
  const [formData, setFormData] = useState({
    influncername: {
      value: marqfluencerData?.influencerName || "",
      isValid: true,
    },
    mobilenumber: {
      value: marqfluencerData?.mobileNumber || "",
      isValid: true,
    },
    email: {
      value: marqfluencerData?.email || "",
      isValid: true,
    },
    instagramurl: {
      value: marqfluencerData?.instagramUrl || "",
      isValid: true,
    },
    youtubeurl: {
      value: marqfluencerData?.youtubeUrl || "",
      isValid: true,
    },
    upiid: {
      value: marqfluencerData?.upiId || "",
      isValid: true,
    },
    upiholdername: {
      value: marqfluencerData?.holderName || "",
      isValid: true,
    },
    description: {
      value: marqfluencerData?.influencerDescription || "",
      isValid: true,
    },
    commercials: {
      value: marqfluencerData?.commercials || "",
      isValid: true,
    },
  });

  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [influncersCategory, setInfluncersCategory] = useState([]);
  const [colabTypeNew, setColabType] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const [loading, setLoading] = useState(true);
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const countries = useCountries();
  const states = useStates(selectedCountryIso2?.country?.name);
  const cities = useCities(age?.state?.name);
  const influncerCategories = useInfluncersCategory();
  const colabtype = useCollbType();
  const navigate = useNavigate();


  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const handleCollabType = (selectedOption) => {
    if (selectedOption) {
      setColabType({
        _id: selectedOption.value,
        colabType: selectedOption.label,
      });
    } else {
      setColabType("");
    }
  };


  const handleInfluncerCategory = (selectedOption) => {
    if (selectedOption) {
      const values = selectedOption.map((option) => ({
        _id: option.value,
        influencerCategory: option.label,
      }));
      setInfluncersCategory(values);
    } else {
      setInfluncersCategory("");
    }
  };

  const editInfluencerIds =
    !influncersCategory?.influencerCategory &&
    influncersCategory.map((influencer) => influencer._id);

  const influencerIds = influncersCategory?.influencerCategory?.map(
    (item) => item._id
  );


  const handleStateChange = (selectedOption) => {
    if (selectedOption) {
      setAge({
        _id: selectedOption.value,
        state: selectedOption.label,
      });
    } else {
      setAge("");
    }
  };
  const handlesetCountryChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCountryIso2({
        _id: selectedOption.value,
        country: selectedOption.label,
      });
    } else {
      setSelectedCountryIso2("");
    }
  };
  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setCity({
        _id: selectedOption.value,
        city: selectedOption.label,
      });
    } else {
      setCity("");
    }
  };

  const selectedCountryName = countries?.find(
    (country) => country.name === selectedCountryIso2.country
  )?._id;
  const selectedStateName = states?.find(
    (state) => state.name === age.state
  )?._id;

  const handlemarqfluencerEdit = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid =
      (editInfluencerIds.length > 0 || influencerIds) &&
      (colabTypeNew?.collaborationType?._id || colabTypeNew?._id) &&
      (marqfluencerData?.mobileNumber || formData.mobilenumber.isValid) &&
      (marqfluencerData?.instagramUrl || formData.instagramurl.isValid) &&
      (marqfluencerData?.youtubeUrl || formData.youtubeurl.isValid);
    if (isFormValid) {
      const editData = {
        influencerName:
          formData.influncername.value || marqfluencerData?.influencerName,
        upiId: formData.upiid.value || marqfluencerData?.upiId,
        mobileNumber:
          formData.mobilenumber.value || marqfluencerData?.mobileNumber,
        email: formData.email.value || marqfluencerData?.email,
        influencerCategory:
          (influencerIds && influencerIds) ||
          (editInfluencerIds && editInfluencerIds),

        collaborationType:
          colabTypeNew?.collaborationType?._id || colabTypeNew?._id,

        holderName:
          formData.upiholdername.value || marqfluencerData?.holderName,
        instagramUrl:
          formData.instagramurl.value || marqfluencerData?.instagramUrl,
        youtubeUrl: formData.youtubeurl.value || marqfluencerData?.youtubeUrl,
        influencerDescription:
          formData.description.value || marqfluencerData?.influencerDescription,
        country: selectedCountryName || marqfluencerData?.country?._id,
        state: selectedStateName || marqfluencerData?.state?._id,
        city: city?.city?._id || city?._id,
      };

      const endpoint = `${VisitorEndpoint.EDIT_INFLUNCER}/${marqfluencerData?._id}`;
      putJsonData(
        endpoint,
        editData,
        () => {},
        (res) => {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/marqfluencer");
            }, 2000);
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setInfluncersCategory(marqfluencerData);
    setSelectedCountryIso2(marqfluencerData);
    setAge(marqfluencerData);
    setColabType(marqfluencerData);
    if (marqfluencerData.city) {
      setCity(marqfluencerData);
    } else {
      setCity(null);
    }
    setLoading(false);
  }, [marqfluencerData]);

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <ActiveLastBreadcrumb
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Marqfluencer", href: "/marqfluencer" },
                  { name: "Edit Marqfluencer", href: "/marqfluencer/edit" },
                ]}
              />
            </div>
            <h1 className="have_solution_heading">Edit Marqfluencer</h1>

            <form onSubmit={handlemarqfluencerEdit}>
              <Card sx={{ padding: "20px" }}>
                <CardContent>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Marqfluencer Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the full name of the influencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="influncername"
                          type="text"
                          placeholder="Enter Marqfluencer Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.influncername.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "influncername", null);
                          }}
                        />
                        {formData.influncername.isValid || (
                          <p className="input_text_error">
                            *Please enter your full name.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Mobile Number
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the mobile number of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="mobilenumber"
                          type="number" 
    onWheel={(e) => e.target.blur()}
                          placeholder="Enter Mobile Number"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.mobilenumber.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "mobilenumber", PATTERNS.MOBILE);
                          }}
                        />
                        {formData.mobilenumber.isValid || (
                          <p className="input_text_error">
                            *Please enter your valid phone number.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Email Address</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the email address of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="email"
                          type="text"
                          placeholder="Enter Email Address "
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.email.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "email", PATTERNS.EMAIL);
                          }}
                        />
                        {formData.email.isValid || (
                          <p className="input_text_error">
                            *Please enter your valid email.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Category
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the category in which Marqfluencer creates
                        content.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={influncerCategories?.allstatus.map(
                            (item) => ({
                              label: item.influencerCategory,
                              value: item._id,
                            })
                          )}
                          value={influncersCategory}
                          onChange={handleInfluncerCategory}
                          name="flavor"
                          isMulti={true}
                          defaultValue={
                            influncersCategory &&
                            influncersCategory?.influencerCategory
                              ? influncersCategory?.influencerCategory?.map(
                                  (category) => ({
                                    label: category.influencerCategory,
                                    value: category._id,
                                  })
                                )
                              : []
                          }
                        />
                        {formSubmitted &&
                          (!influncersCategory ||
                            editInfluencerIds.length === 0) && (
                            <p className="input_text_error">
                              *Please select at least one category.
                            </p>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Collaboration Type
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the collaboration preference of the Marqfluencer
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={colabtype?.allstatus.map((item) => ({
                            label: item.colabType,
                            value: item._id,
                          }))}
                          value={colabTypeNew}
                          onChange={handleCollabType}
                          // onChange={handleChange}
                          name="flavor"
                          defaultValue={
                            colabTypeNew && colabTypeNew.collaborationType
                              ? {
                                  label:
                                    colabTypeNew?.collaborationType?.colabType,
                                  value: colabTypeNew?.collaborationType?._id,
                                }
                              : null
                          }
                        />
                        {formSubmitted && !colabTypeNew && (
                          <p className="input_text_error">
                            *Please select a collaboration type.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Commercials</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the commercials of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="commercials"
                          type="number" 
    onWheel={(e) => e.target.blur()}
                          placeholder="Enter Commercials"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.commercials.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "commercials", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Instagram URL</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the instagram profile link of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="instagramurl"
                          type="text"
                          placeholder="Enter Instagram URL"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.instagramurl.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "instagramurl", PATTERNS.WEBSITE_URL);
                          }}
                        />
                        {formData.instagramurl.isValid || (
                          <p className="input_text_error">
                            *Please enter your Valid URL.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Youtube URL</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the youtube profile link of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="youtubeurl"
                          type="text"
                          placeholder="Enter Youtube URL"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.youtubeurl.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "youtubeurl", PATTERNS.WEBSITE_URL);
                          }}
                        />
                        {formData.youtubeurl.isValid || (
                          <p className="input_text_error">
                            *Please enter your valid URL.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Country Name</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the country name of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={countries.map((item) => ({
                            label: item.name,
                            value: item.name,
                          }))}
                          value={selectedCountryIso2}
                          onChange={handlesetCountryChange}
                          // onChange={handleChange}
                          name="flavor"
                          defaultValue={
                            selectedCountryIso2 && selectedCountryIso2.country
                              ? {
                                  label: selectedCountryIso2?.country?.name,
                                  value: selectedCountryIso2?._id,
                                }
                              : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>State Name</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the state name of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={states.map((item) => ({
                            label: item.name,
                            value: item.name,
                          }))}
                          value={age}
                          // defaultValue={options[0]}
                          onChange={handleStateChange}
                          name="flavor"
                          defaultValue={
                            age && age.state
                              ? {
                                  label: age?.state?.name,
                                  value: age?._id,
                                }
                              : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>City Name</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the city name of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={[
                            {
                              label: "Other",
                              value: "65f28af9115b7ec97647b52d",
                            },
                            ...cities.map((item) => ({
                              label: item.name,
                              value: item._id,
                            })),
                          ]}
                          value={city}
                          onChange={handleCityChange}
                          name="flavor"
                          defaultValue={
                            city && city
                              ? {
                                  label: city?.city?.name,
                                  value: city?.city?._id,
                                }
                              : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the UPI Id of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="upiid"
                          type="text"
                          placeholder="Enter UPI Id"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.upiid.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "upiid", null);
                          }}
                        />
                        {formData.upiid.isValid || (
                          <p className="input_text_error">
                            *Please enter your valid UPI Id.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>UPI Holder Name</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the holder name of the UPI Id of the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="upiholdername"
                          type="text"
                          placeholder="Enter Upi Holder Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.upiholdername.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "upiholdername", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Marqfluencer Description
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the short description about the Marqfluencer.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="description"
                          type="text"
                          placeholder="Enter Description"
                          id="outlined-required"
                          label="First Name"
                          multiline
                          rows={4}
                          helper
                          value={formData.description.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "description", PATTERNS.ADMIN_NOTE);
                          }}
                        />
                        {formData.description.isValid || (
                          <p className="input_text_error">
                            *Please enter a note below 250 words.
                          </p>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid xs={12} sm={12}>
                    {formSubmitted &&
                      (!influncersCategory ||
                        editInfluencerIds.length === 0 ||
                        !colabTypeNew) && (
                        <p
                          className="input_text_error"
                          style={{ textAlign: "center" }}
                        >
                          *There are some errors, please check!
                        </p>
                      )}
                    <SubmitButton
                      type={isDisabledNew ? "button" : "submit"}
                      label={isDisabledNew ? "Loading..." : "Submit"}
                      disabled={isDisabledNew}
                    />{" "}
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default EditMarqfluencer;
