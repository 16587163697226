import React, { useState } from 'react';
import Select from 'react-select';

const SingleSelect = ({ options,menuPlacement, defaultValue,isDisabled , onChange, name ,isMulti}) => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #cccccc",
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      color: "#000000",
      fontFamily: "Outfit, sans-serif",
      fontWeight: 500,
      fontSize: "14px",
      boxShadow: state.isFocused ? "0px 0px 5px #00c6bd" : null,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#163e6e" : "#FFFFFF",
      color: state.isSelected ? "#FFFFFF" : "#5A5A5A",
      fontFamily: "Outfit, sans-serif",
      fontSize: "16px",
    }),
  };
  return (
    <Select
      className={isMulti ? "basic-multi-select" : "basic-single"}
      classNamePrefix="select"
      defaultValue={defaultValue}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      name={name}
      options={options}
      styles={customStyles}
      onChange={onChange}
      menuPlacement={menuPlacement || "auto"}
    />
  );
};
export default SingleSelect;
