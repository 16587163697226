import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetAllAdmin,
  useGetPayment,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import { SmallButton } from "../../component/coustombutton/SmallButton";
import CustomButton from "../../component/coustombutton/CoustomButton";

const AdminComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "name", label: "Name", minWidth: 200 },
    { id: "email", label: "Email", minWidth: 200 },
    { id: "mobilenumber", label: "Mobile Number", minWidth: 200 },
    { id: "role", label: "Role", minWidth: 200 },
  ];
  const perPageLimit = 9;
  const { allstatus, loading, totalPages, totalCount } = useGetAllAdmin(
    currentPage,
    perPageLimit
  );
  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,

    name: item.name,
    email: item.email,
    mobilenumber: item.mobileNumber,
    role: item.role,
  }));

  const actions = [
    {
      icon: EditIcon,
      onClick: (row) => console.log("Edit", row),
    },
    {
      icon: VisibilityIcon,
      onClick: (row) => console.log("View", row),
    },
    {
      icon: DeleteIcon,
      onClick: (row) => console.log("Delete", row),
    },
  ];
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
        <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Sub Admin", href: "/admin" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Admin</p>
              {/* <span>1000 Total Payments</span> */}
            </div>
            <div>
           <CustomButton label="Add Brand" background="#ffffff" color="#163e6e"/>
            </div>
          </div>
          {/* <SmallButton label="Add Brand" background="#ffffff" color="#163e6e"/> */}

          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
          {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
            itemCount={perPageLimit}
            totalCount={totalCount}
          /> */}
        </div>
      </div>
    </>
  );
};
export default AdminComponent;
