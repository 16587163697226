import React, { useState } from "react";
import {
  CustomRequiredLabel,
  CustomInputLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { PATTERNS } from "../validetion";
import { Card, CardContent, FormControl, Grid } from "@mui/material";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import {
  useGetBrand,
  useGetCampaign,
  useGetPayment,
  useGetPayments,
} from "../customHooks/customHook";
import { postJsonData, putJsonData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import SubmitButton from "../coustombutton/SubmitButton";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";

const AddPayment = () => {
  const { state } = useLocation();
  const paymentData = state?.selectedData || {};
  const [formData, setFormData] = useState({
    paymentamount: {
      value: paymentData?.paymentAmount || "",
      isValid: true,
    },
    paymentdate: {
      value: paymentData?.paymentDate
        ? new Date(paymentData?.paymentDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [allBrand, setallBrand] = useState("");
  const [paymentModetype, setPaymentModetype] = useState("");
  const [camapignName, setCamapignName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const navigate = useNavigate();
  const brandDetails = useGetBrand();
  const paymentMode = useGetPayment();
  const campaign = useGetCampaign();

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

 
  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand(selectedOption.value);
    } else {
      setallBrand("");
    }
  };
  const handlePaymentMode = (selectedOption) => {
    if (selectedOption) {
      setPaymentModetype(selectedOption.value);
    } else {
      setPaymentModetype("");
    }
  };
  const handleCampaign = (selectedOption) => {
    if (selectedOption) {
      setCamapignName(selectedOption.value);
    } else {
      setCamapignName("");
    }
  };
  const handlePayment = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid =
      formData.paymentamount.isValid &&
      // formData.taxinvoicenumber.isValid &&
      // formData.Pioicenumber.isValid &&
      paymentModetype &&
      paymentModetype &&
      formData.paymentamount.value.trim() !== "";
    if (isFormValid) {
      const data = {
        brand: allBrand,
        paymentAmount: formData.paymentamount.value,
        paymentDate: formData.paymentdate.value,
        paymentMode: paymentModetype,
        campaign: camapignName,
      };
      postJsonData(
        VisitorEndpoint.ADD_PAYMENT,
        data,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/payment");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  return (
    <div className="container_fluid_new">
      <div className="container_new">
        <div>
          <ActiveLastBreadcrumb
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Payment", href: "/payment" },
              Object.keys(paymentData).length > 0
                ? { name: "Edit Payment", href: "/payment/add" }
                : { name: "Add Payment", href: "/payment/add" },
            ]}
          />
        </div>
        <h1 className="have_solution_heading">Add Payment</h1>
        <form onSubmit={handlePayment}>
          <Card sx={{ padding: "20px" }}>
            <CardContent>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Brand Name</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the brand for which payment is received.
                  </CustomSubheadingh>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={brandDetails?.allstatus.map((item) => ({
                        label: item.brandName,
                        value: item._id,
                      }))}
                      value={allBrand}
                      // defaultValue={preSelectedBrand ? { label: preSelectedBrand.brandName, value: preSelectedBrand._id } : null}
                      onChange={handleBrand}
                      name="flavor"
                      isDisabled={
                        paymentData && paymentData.brand
                          ? !!paymentData.brand.brandName
                          : false
                      }
                    />
                    {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                    Campaign Name
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the campaign for which payment is received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={campaign?.allstatus.map((item) => ({
                        label: item.campaignName,
                        value: item._id,
                      }))}
                      value={camapignName}
                      // defaultValue={(preSelectedCampaign ? { label: preSelectedCampaign.campaignName, value: preSelectedCampaign._id } : null)}
                      onChange={handleCampaign}
                      name="flavor"
                      isDisabled={
                        paymentData && paymentData.campaign
                          ? !!paymentData.campaign.campaignName
                          : false
                      }
                    />
                 
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Payment Date
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the date on which payment is received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="paymentdate"
                      type="date"
                      placeholder="Enter Payment Date"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.paymentdate.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "paymentdate", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Payment Mode
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Select the mode on which payment is received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <SingleSelect
                      options={paymentMode?.allstatus.map((item) => ({
                        label: item.paymentMode,
                        value: item._id,
                      }))}
                      value={paymentModetype}
                      // value={paymentModetype || (preSelectedmode ? { label: preSelectedmode.paymentMode, value: preSelectedmode._id } : null)}
                      onChange={handlePaymentMode}
                      name="flavor"
                    />
                    {formSubmitted && !paymentModetype && (
                      <p className="input_text_error">
                        *Please select payment Mode.
                      </p>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>
                    Payment Amount
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Enter the amount which have been received.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="paymentamount"
                      type="number" 
    onWheel={(e) => e.target.blur()}
                      placeholder="Enter Payment Amount"
                      id="outlined-required"
                      label="First Name"
                      helper
                      value={formData.paymentamount.value}
                      size="small"
                      onChange={(e) => {
                        setValues(e, "paymentamount", null);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <SubmitButton
                  type={isDisabledNew ? "button":"submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew}
                />{" "}
            </CardContent>
          </Card>
        </form>
      </div>
    </div>
  );
};
export default AddPayment;
