import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useCountries,
  useGetExportExcel,
  useGetWebInfluncersDeatils,
  useInfluncersCategory,
  useStates,
} from "../../component/customHooks/customHook";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RemovePopup from "../../component/removemasterdata/removePopup";
import { Brand } from "../../component/IconsImport";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import FilterPopupComponent from "../../common/filter/filterPopup";
import WebMarqinfluncersView from "../../component/viewdetails/WebMarqinfluncersView";
import { useDispatch, useSelector } from "react-redux";
import {
  setMarqfluencerFilter,
  setWebMarqfluencerFilter,
} from "../../store/FilterSlice";
import * as XLSX from "xlsx";
import { SmallButton } from "../../component/coustombutton/SmallButton";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
const sortArray = [
  // {
  //   sortOrder: "asc",
  //   sortField: "brandName",
  //   sortName: "Brand Name (Asc)",
  // },
  // {
  //   sortOrder: "desc",
  //   sortField: "updatedAt",
  //   sortName: "Updated Date (Dsc)",
  // },
];

const WebInfluncerDetailsComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector(
    (state) => state.filter.webMarqfluencerFilter
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [flattenData, setFlattenData] = useState([]);

  const { allstatus, loading, totalPages, totalCount } =
    useGetWebInfluncersDeatils(
      currentPage,
      perPageLimit,
      sortField,
      sortOrder,
      filterParam,
      triggerRefresh
    );
  const allstatusExport = useGetExportExcel(filterParam, triggerRefresh);
  const influencercategies = useInfluncersCategory();
  const countries = useCountries();
  const states = useStates("India");
  // const cities = useCities(age?._id);
  const MarqfluencerDetails = allstatusExport?.allstatusExport;

  let filters = [
    {
      label: "Category",
      urlParam: "influencercategory",
      values: influencercategies?.allstatus.map((item) => ({
        label: item.influencerCategory,
        value: item._id,
      })),
    },
    {
      label: "Country",
      urlParam: "country",
      values: countries.map((item) => ({
        label: item.name,
        value: item._id,
      })),
    },
    {
      label: "State",
      urlParam: "state",
      values: states.map((item) => ({
        label: item.name,
        value: item._id,
      })),
    },
    // const cityFilters = {
    //   label: "Category",
    //   urlParam: "influencercategory",
    //   values: influencercategies?.allstatus.map((item) => ({
    //     label: item.influencerCategory,
    //     value: item._id,
    //   })),
    // };
    {
      label: "Collaboration Preference",
      urlParam: "collaborationpreference",
      values: [
        { label: "Paid", value: "paid" },
        { label: "Barter", value: "barter" },
        { label: "Both", value: "both" },
      ],
    },
  ];
  const [editState, setEditState] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 100 },

    { id: "name", label: "Name", minWidth: 200 },
    { id: "number", label: "Number", minWidth: 200 },
    { id: "email", label: "Email", minWidth: 200 },
    { id: "collaboration", label: "Collaboration Preference", minWidth: 200 },
    { id: "city", label: "City", minWidth: 200 },
  ];

  // const flattenData = MarqfluencerDetails && MarqfluencerDetails.map((item) => {
  //   const newItem = { ...item };
  //   delete newItem["Social Media"];
  //   // Flatten the Content Category array
  //   newItem["Content Category"] = item["Content Category"].join(", ");
  //   newItem["Other Category"] = (item["Other Category"] && item["Other Category"].length > 0) ? item["Other Category"].join(", ") :item["Other Category"];
  //   // newItem["Other Category"] = item["Other Category"][0]?item["Other Category"][0]:"jjj";
  //   // Check if Social Media exists before calling forEach
  //   if (item["Social Media"]) {
  //     // Flatten each social media object
  //     item["Social Media"].forEach((sm, index) => {
  //       newItem[`Social Platform ${index + 1}`] = sm.socialPlatform;
  //       newItem[`Profile URL ${index + 1}`] = sm.profileUrl;
  //       newItem[`Followers Count ${index + 1}`] = sm.followersCount;
  //     });
  //   }

  //   return newItem;
  // });
  useEffect(() => {
    if (MarqfluencerDetails) {
      const newData = MarqfluencerDetails.map((item) => {
        const newItem = { ...item };
        delete newItem["Social Media"];
        newItem["Content Category"] = item["Content Category"].join(", ");
        newItem["Other Category"] =
          item["Other Category"] && item["Other Category"].length > 0
            ? item["Other Category"].join(", ")
            : item["Other Category"];
        if (item["Social Media"]) {
          item["Social Media"].forEach((sm, index) => {
            newItem[`Social Platform ${index + 1}`] = sm.socialPlatform;
            newItem[`Profile URL ${index + 1}`] = sm.profileUrl;
            newItem[`Followers Count ${index + 1}`] = sm.followersCount;
          });
        }
        return newItem;
      });
      setFlattenData(newData);
      setIsDataReady(true);
    }
  }, [MarqfluencerDetails]);
  const handleExport = () => {
    if (isDataReady) {
      const ws = XLSX.utils.json_to_sheet(flattenData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "MarqfluencerDetails.xlsx");
    }
  };

  // const perPageLimit = 9;
  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,
    name: item.fullName,
    email: item.email,
    number: item.mobileNumber,
    city: item.city?.name,
    collaboration: item.collaborationPreference,
  }));
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };

  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },

    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
  ];

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
      dispatch(setWebMarqfluencerFilter(value));
      setCurrentPage(1);
      setTriggerRefresh((prev) => !prev);
    }
  };

  const filterToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_WEB_INFLUNCERS"
        onSuccess={refreshData}
      />
      <WebMarqinfluncersView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />

      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Marqfluencer", href: "/marqfluencer" },

                {
                  name: "Marqfluencer Registerations",
                  href: "/marqfluencer/details",
                },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Marqfluencer Web Registerations</p>
              <span>{totalCount}&nbsp;Total Marqfluencers Registerations</span>
            </div>
            <div>
              <Tooltip title="Export to Excel" arrow>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    background: "#fff",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleExport}
                >
                  <SimCardDownloadIcon
                    sx={{ width: "25px", height: "25px", color: "#163e6e" }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              isOpen={isOpen}
              filterKey="webMarqfluencerFilter"
            />

            {/* <SortPopup sortFunction={selectSort} sortArray={sortArray} /> */}
          </div>
          {/* <FilterPopupComponent
            filters={filters}
            onChange={setParam}
            isOpen={isOpen}
          /> */}
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default WebInfluncerDetailsComponent;
