// EditModal.js
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, FormControl } from "@mui/material";
import {
  deleteJsonData,
  patchFormData,
  postJsonData,
  putFormData,
  putJsonData,
} from "../../Services/AxoisInterceptorr";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { useGetStatus } from "../customHooks/customHook";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { SmallButton } from "../coustombutton/SmallButton";

const RemovePopup = ({
  open,
  onClose,
  row,
  onFormSubmit,
  editState,
  endpoint,
  onSuccess

}) => {
  let removeEndpoint = endpoint;
  useEffect(() => {
    if (editState) {
      setFormData({ brandCategory: editState?.brandCategory });
    } else {
      setFormData({ brandCategory: "" });
    }
  }, [editState]);
  const [formData, setFormData] = useState({
    brandCategory: editState?.brandCategory ? editState.brandCategory : "",
  });
  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

;
  const handleDelete = () => {
    let endpoint = `${VisitorEndpoint[removeEndpoint]}/${editState?._id}`;

    deleteJsonData(
      endpoint,
      null,
      (res) => {
        toast.success(res?.data.message);
        onClose();
        onSuccess && onSuccess();

        
      },
      (error) => {
        onClose();
        toast.error(error.response.data.message);
      }
    );
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth:400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          background: "#FFFFFF",
          padding: "50px",

          borderRadius: "20px",
        }}
      >
        <h2 className="edit_heading">Are you sure you want to delete?</h2>
        {/* <TextField
          select
          label="Status"
          name="status"
          value={formData.status}
          onChange={handleFormChange}
          fullWidth
          sx={{ fontFamily: '"Quicksand", sans-serif' }}
        >
          {allstatus.map((stauts) => (
            <MenuItem
              key={stauts.status}
              value={stauts.status}
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                fontFamily: "Quicksand, sans-serif",
              }}
            >
              {stauts.status}
            </MenuItem>
          ))}
        </TextField> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap:"30px"
          }}
        >
        
          <SmallButton
             onClick={handleDelete}
            label= "Yes, Delete It" 
            background="red"   
           
          />
          <SmallButton
            onClick={onClose}
            label= "Cancel"    
           width="100%"
           
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemovePopup;
