import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { putJsonData } from "../../Services/AxoisInterceptorr";
import { useGetStatus } from "../customHooks/customHook";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import SingleSelect from "../../common/select/SingleSelect";
import { validateInput } from "../validetionUtility";
import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";

const AdminNotePopup = ({
  open,
  onClose,
  row,
  onFormSubmit,
  editState,
  endpoint,
  statusGroup,
  showStatus = true,
  onSuccess,
}) => {
  let removeEndpoint = endpoint;

  const [formData, setFormData] = useState({
    adminnote: "",
  });
  const [campaignStatus, setCamapignStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErorr] = useState("");

  const statusCampaign = useGetStatus("", "", statusGroup);
  useEffect(() => {
    setLoading(true);
    setCamapignStatus(editState);

    setLoading(false);
  }, [editState, row]);

  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
    setErorr("");
  };

  const handleCampaignStatus = (selectedOption) => {
    if (selectedOption) {
      setCamapignStatus({
        _id: selectedOption.value,
        status: selectedOption.label,
      });
    } else {
      setCamapignStatus("");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.adminnote?.value) {
      setErorr("Admin note is required");
      return;
    } else if (showStatus && !campaignStatus) {
      return;
    }
    setIsLoading(true);
    const data = {
      adminNote: formData.adminnote?.value,
    };
    if (showStatus) {
      data.status = campaignStatus?.status?._id || campaignStatus?._id;
    }

    let endpoint = `${VisitorEndpoint[removeEndpoint]}/${editState?._id}`;

    putJsonData(
      endpoint,
      data,
      () => {},
      (res) => {
        if (res) {
          if (res && res.status === 200) {
            toast.success(res.data.message);
            onClose();
            onSuccess && onSuccess();
          }
        }
        setIsLoading(false);
      },
      (error) => {
        onClose();
        toast.error(error.response.data.message);
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      {loading && loading ? (
        <CircularProgress />
      ) : (
        <>
          <Modal open={open} onClose={onClose}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                background: "#FFFFFF",
                padding: "50px",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CloseIcon
                  onClick={onClose}
                  sx={{
                    color: "red",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                />
              </div>
              <h2 className="admin_note_heading">
                Add {showStatus && "Status &"} Note
              </h2>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: "20px", marginTop: "20px" }}
              >
                {showStatus && (
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Status</CustomRequiredLabel>

                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={statusCampaign?.allstatus.map((item) => ({
                          label: item.status,
                          value: item._id,
                        }))}
                        value={campaignStatus}
                        onChange={handleCampaignStatus}
                        name="flavor"
                        defaultValue={
                          row?.status && row?.status
                            ? {
                                label: row?.status,
                                value: row?.status,
                              }
                            : ""
                        }
                      />

                      {showStatus && !campaignStatus && (
                        <p className="input_text_error">
                          *Please select Status.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>Add Note</CustomRequiredLabel>

                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      required
                      name="adminnote"
                      type="text"
                      placeholder="Enter Note Here"
                      id="outlined-required"
                      label="First Name"
                      multiline
                      rows={4}
                      helper
                      size="small"
                      onChange={(e) => {
                        setValues(e, "adminnote", null);
                      }}
                    />
                  </FormControl>
                  <p className="input_text_error">{error}</p>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <SmallButton
                  onClick={handleSubmit}
                  width="100%"
                  label={isLoading ? "Loading..." : "Submit"}
                  disabled={isLoading}
                />
              </Grid>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default AdminNotePopup;
