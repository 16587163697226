import React, { useState } from "react";
import { Box, Button, TablePagination, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetReimbursement,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import ReimbursementView from "../../component/viewdetails/ReimbursementView";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import RemovePopup from "../../component/removemasterdata/removePopup";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import FilterPopupComponent from "../../common/filter/filterPopup";
import HistoryIcon from "@mui/icons-material/History";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import { useDispatch, useSelector } from "react-redux";
import { setReimbursedFilter } from "../../store/FilterSlice";
import { useUser } from "../../contexts/UserDataStore";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadExcelPopUp from "../../component/adminnote/UploadExcelPopUp";
const sortArray = [
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
  {
    sortOrder: "desc",
    sortField: "paymentDate",
    sortName: "Reimbursement Date (Dsc)",
  },
  {
    sortOrder: "asc",
    sortField: "reimbursementValue",
    sortName: "Reimbursement Value: Low to High",
  },
  {
    sortOrder: "desc",
    sortField: "reimbursementValue",
    sortName: "Reimbursement Value: High to Low",
  },
];

const ReimbursementComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.reimbursedFilter);

  const [currentPage, setCurrentPage] = useState(1);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [editState, setEditState] = useState();
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [openReviewNote, setopenReviewNote] = useState(false);

  const { user } = useUser();

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "influencer", label: " Marqfluencer Name", minWidth: 200 },
    { id: "campaignname", label: "Campaign Name", minWidth: 200 },
    { id: "reimbursementvalue", label: " Reimbursed Amount", minWidth: 200 },
    { id: "paymentdate", label: "  Reimbursement Date", minWidth: 200 },
    { id: "orderidreim", label: "  Order Id", minWidth: 200 },
  ];
  const transactionFilter = useGetReimbursement();

  const { allstatus, loading, totalPages, totalCount } = useGetReimbursement(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh
  );

  // const influncerFilter = useGetInfluncers()
  const filteredTransactions = transactionFilter?.allstatus.filter(
    (item) => item.transactionNumber !== ""
  );
  const influencerFilter = transactionFilter?.allstatus
  .filter((item) => item.influencer?._id && item.influencer?.influencerName)
  .map((item) => ({
    id: item.influencer._id,
    name: item.influencer.influencerName,
  }));
  const uniqueinfluencerData = influencerFilter.filter(
    (influencer, index, self) =>
      index ===
      self.findIndex(
        (c) => c.id === influencer.id && c.name === influencer.name
      )
  );
  const campaignFilter = transactionFilter?.allstatus
  .filter((item) => item.campaign?._id && item.campaign.campaignName)
  .map((item) => ({
    id: item.campaign._id,
    name: item.campaign.campaignName,
  }));
  const uniquecampaignData = campaignFilter.filter(
    (campaign, index, self) =>
      index ===
      self.findIndex(
        (c) => c.id === campaign.id && c.name === campaign.name
      )
  );
  const orderIdFilter = transactionFilter?.allstatus
  .filter((item) => item.order?._id && item.order.orderId)
  .map((item) => ({
    id: item.order._id,
    name: item.order.orderId,
  }));
  const uniqueorderIdData = orderIdFilter.filter(
    (order, index, self) =>
      index ===
      self.findIndex(
        (c) => c.id === order.id && c.name === order.name
      )
  );
  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,

    // influencername: item.influencer.influencerName,
    // brandname: item.brand.brandName,
    campaignname: item.campaign && item.campaign.campaignName,
    influencer: item.influencer && item.influencer.influencerName,
    // paymentamount: item.paymentAmount,
    paymentdate: new Date(item.paymentDate).toISOString().split("T")[0],
    reimbursementvalue: item.reimbursementValue,
    orderidreim: item.order && item.order?.orderId,
  }));
  let filters = [
    {
      label: "Marqfluencer Name",
      urlParam: "influencername",
      values: uniqueinfluencerData?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },

    {
      label: "Campaign Name",
      urlParam: "campaignname",
      values: uniquecampaignData?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },
    {
      label: "Order Id",
      urlParam: "orderid",
      values: uniqueorderIdData?.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    },

    {
      label: "Transaction Number",
      urlParam: "transactionnumber",
      values: filteredTransactions.map((item) => ({
        label: item.transactionNumber,
        value: item.transactionNumber,
      })),
    },
  ];
  const actions = [
    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        navigate("/reimbursement/edit", { state: { selectedData } });
 
      },
    },
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setHistoryDrawerOpen(true);
      },
    },
    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },

    {
      icon: NoteAddIcon,

      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row);
        handleOpenModal();
      },
    },
  ];

  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };
  const setParam = (value) => {
    if (filterParam !== value) {
    dispatch(setReimbursedFilter(value));
    setCurrentPage(1);
    setTriggerRefresh(prev => !prev);}
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };
  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const handleCloseModal = () => {
    setOpenModalNote(false);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  const handleExport = () => {
    setopenReviewNote(true);

  };
  const handleReviewCloseModal = () => {
    setopenReviewNote(false);
  };
  return (
    <>
      <ReimbursementView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_REIMBURSEMENT"
        onSuccess={refreshData}
      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />

      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        showStatus={false}
        // onFormSubmit={/* Pass any necessary submit function here */}
        editState={editState}
        endpoint="ADMIN_REIMBURSEMENT_NOTE"
        onSuccess={refreshData}
      />
           <UploadExcelPopUp
        open={openReviewNote}
        onClose={handleReviewCloseModal}
        onSuccess={refreshData}
      />

      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Reimbursement", href: "/reimbursement" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Reimbursement</p>
              <span>{totalCount}&nbsp;Total Reimbursement</span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {user?.role === "Admin" && (
                <>
                  <div>
                    <Tooltip title="Upload Excel File" arrow>
                      <div
                        style={{
                          width: "35px",
                          height: "35px",
                          background: "#fff",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleExport}
                      >
                        <UploadFileIcon
                          sx={{
                            width: "25px",
                            height: "25px",
                            color: "#163e6e",
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}
              <div>
              <CustomButton
                onClick={() => navigate("/reimbursement/add")}
                label="Add Reimbursement"
              />
            </div>
            </div>
           
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
            <FilterPopupComponent
              filters={filters}
              onChange={setParam}
              
              isOpen={isOpen}
              filterKey="reimbursedFilter" 

            />
          </div>
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ReimbursementComponent;
