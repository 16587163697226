import React, { useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ShortingTable from "../../component/tabel/ShortingTabel";
import { useGetCampaignProgressDeatils } from "../../component/customHooks/customHook";
import CustomButton from "../../component/coustombutton/CoustomButton";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";


const CampaignProgress = () => {

  const [currentPage, setCurrentPage] = useState(1);

  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "campaignnamestats", label: "Campaign Name", minWidth: 100 },
    { id: "requestedorder", label: "Requested Orders", minWidth: 100 },
    // { id: "productname", label: "Product Name", minWidth: 200 },
    { id: "completedorder", label: "Completed Orders", minWidth: 100 },
    { id: "reviewedorder", label: "Reviewed Orders", minWidth: 100 },
    { id: "cancelledorder", label: "Cancelled Orders", minWidth: 100 },
    { id: "returnedorder", label: "Returned Orders", minWidth: 100 },
    { id: "invalidorder", label: "Invalid Orders", minWidth: 100 },
    { id: "updateddate", label: "Last Updated At", minWidth: 100 },
    { id: "createddate", label: "Last Created At", minWidth: 100 },
  ];
  const { allstatus, loading, totalPages, totalCount } =
    useGetCampaignProgressDeatils(currentPage, perPageLimit, triggerRefresh);
  const realData = allstatus.map((item) => ({
    id: item._id,
    sno: item.sno,
    campaignnamestats: item.campaignName,
    requestedorder: item.noOfContent && item.noOfContent,
    completedorder: item.totalCompletedOrders && item.totalCompletedOrders,
    reviewedorder: item.totalReviewedOrders && item.totalReviewedOrders,
    cancelledorder: item.totalCancelledOrders && item.totalCancelledOrders,
    returnedorder: item.totalReturnedOrders && item.totalReturnedOrders,
    invalidorder: item.totalInvalidOrders && item.totalInvalidOrders,
    updateddate: item.latestUpdatedAt && item.latestUpdatedAt,
    createddate: item.latestCreatedAt && item.latestCreatedAt,
  }));



  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Campaign Progress", href: "/campaignprogress" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Campaign Progress</p>
              <span>{totalCount}&nbsp;Campaigns</span>
            </div>
            {/* <div>
              <CustomButton
                onClick={() => navigate("/campaign/add")}
                label="Add Campaign"
              />
            </div> */}
          </div>
          {/* <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SortPopupComponent
              sortFunction={selectSort}
              sortArray={sortArray}
            />
        
          </div> */}
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={false}
            isScroll={true}
            onClickLink={(row) => {
              navigate(`/campaign/stats/${row.id}`)
            }}
          />
          
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
          
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default CampaignProgress;
