import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Tooltip,
  Avatar,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import { NoDataImage } from "../IconsImport";
import { utcToIst } from "../../common/utctimechange";

export default function CollabViewDetails({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);

  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "status", label: " Current Status,", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
    { id: "updatedby", label: "Updated By", minWidth: 200 },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };
  const realData = rowData?.adminNoteHistory.map((item,index) => ({
    sno:index+1,

    adminnote: item.adminNote && item.adminNote,
    status: item.status && item.status?.status,
    updatedat:
    item.updatedAt && utcToIst(item.updatedAt),    updatedby: item.updatedBy?.name,
  }));
  const list = (
    <Box
   
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>Payment Details</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            {showDetails && (
              <Tooltip placement="top" title="Payment History">
                <HistoryIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(true);
                    setShowDetails(false);
                  }}
                />
              </Tooltip>
            )}
            {showHistory && (
              <Tooltip placement="top" title="Payment Details">
                <VisibilityIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(false);
                    setShowDetails(true);
                  }}
                />
              </Tooltip>
            )}
          </div>
          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
              {showHistory && (
                <>
                  <ShortingTable
                    columns={columns}
                    // loading={activeCampaignDetailsLoading}
                    data={realData}
                  />

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
              {showDetails && (
                <>
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Brand Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.brand && rowData?.brand.brandName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Campaign Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.campaign.campaignName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Marqfluencer Name</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      { rowData?.influencer && rowData?.influencer.influencerName}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {rowData?.product && (
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                      <div>
                        <CustomInputLabel>Product Name</CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {rowData?.product.productName}
                      </CustomProductSubheadingh>
                    </Grid>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Collab Type</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.colabType.colabType}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Collab Platform</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.colabPlatform
                        .map((item) => item.platform)
                        .join(", ")}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Collab Value</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.colabValue}
                    </CustomProductSubheadingh>
                  </Grid>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {rowData?.productTrackingNumber && (
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                      <div>
                        <CustomInputLabel>
                          Product Tracking No.
                        </CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {rowData?.productTrackingNumber}
                      </CustomProductSubheadingh>
                    </Grid>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {rowData?.courierName && (
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                      <div>
                        <CustomInputLabel>Courier Name</CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {rowData?.courierName}
                      </CustomProductSubheadingh>
                    </Grid>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {rowData?.deliveryDate && (
                    <>
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                        <div>
                          <CustomInputLabel>Delivery Date</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {
                            new Date(rowData?.deliveryDate)
                              .toISOString()
                              .split("T")[0]
                          }
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  {rowData?.paymentMode && (
                    <>
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>Payment Mode</CustomInputLabel>
                    </div>
                    <CustomProductSubheadingh>
                      {rowData?.paymentMode.paymentMode}
                    </CustomProductSubheadingh>
                  </Grid>
                  </>)}
             
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {rowData?.upiId && (
                    <>
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                        <div>
                          <CustomInputLabel>UPI Id</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.upiId}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {rowData?.holderName && (
                    <>
                      <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                        <div>
                          <CustomInputLabel>UPI Holder Name</CustomInputLabel>
                        </div>
                        <CustomProductSubheadingh>
                          {rowData?.holderName}
                        </CustomProductSubheadingh>
                      </Grid>
                    </>
                  )}
                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                  {/* {rowData?.deliverables && (
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                      <div>
                        <CustomInputLabel>Deliverables</CustomInputLabel>
                      </div>

                      {rowData.deliverables.map((item, index) => (
                        <div key={index}>
                          <div>
                            <CustomInputLabel>
                              Deliverables Type
                            </CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.deliverablesType}
                          </CustomProductSubheadingh>

                          <div>
                            <CustomInputLabel>
                              Deliverables URL
                            </CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.deliverablesUrl}
                          </CustomProductSubheadingh>

                          <div>
                            <CustomInputLabel>Number Of Views</CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.numberOfViews}
                          </CustomProductSubheadingh>

                          <div>
                            <CustomInputLabel>Number Of Likes</CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.numberOfLikes}
                          </CustomProductSubheadingh>

                          <div>
                            <CustomInputLabel>Number Of Share</CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.numberOfShare}
                          </CustomProductSubheadingh>

                          <div>
                            <CustomInputLabel>
                              Number Of Comment
                            </CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.numberOfComment}
                          </CustomProductSubheadingh>

                          <div>
                            <CustomInputLabel>Number Of Save</CustomInputLabel>
                          </div>
                          <CustomProductSubheadingh>
                            {item.numberOfSave}
                          </CustomProductSubheadingh>
                          <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                        </div>
                        
                      ))}
                    </Grid>
                  )} */}
                </>
              )}
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ "& .MuiDrawer-paper": { overflow: "auto",width:"90%", } }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
